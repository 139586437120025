import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from '@/components/ui/toaster'
import { ThemeProvider } from '@/components/theme-provider'
import router from '@/router'
import '@/index.css'
import store from './store/redux/store'
import { RedQueryClientProvider } from './config/reactQuery'
import { Toaster as HotToaster } from 'react-hot-toast'
import "./sentry.ts";
import LazyLoader from '@/components/global/LazyLoader.tsx'
import { NotificationProvider } from './components/ui/notificationProvider.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <RedQueryClientProvider>
      <ThemeProvider defaultTheme='light' storageKey='vite-ui-theme'>
        <NotificationProvider>
        <React.Suspense fallback={<LazyLoader />}>
          <RouterProvider router={router} />
        </React.Suspense>
        </NotificationProvider>
        <Toaster />
        <HotToaster containerClassName='text-sm' />
      </ThemeProvider>
    </RedQueryClientProvider>
  </Provider>
  // </React.StrictMode>
)
