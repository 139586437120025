import { Card } from "@/components/ui/card";
import BarChartComponent from "../chart-components/barChart";
import { useEffect, useState } from "react";


const MedicalCausesComponent = ({medicalCausesData}) => {

    const [medicalCauses, setmedicalCauses] = useState([]);

    function formatTop10Data(rawData: any) {
        // Convert the input object into an array of {type, value}, removing empty keys
        const filteredData = Object.entries(rawData)
          .filter(([key]) => key.trim() !== "") // Exclude empty keys
          .map(([key, value]) => ({ type: key, value })); // Format as {type, value}
        
        // Sort the data in descending order of the value and take the top 10
        const top10Data = filteredData
          .sort((a: any, b: any) => b.value - a.value)
          .slice(0, 10);
      
          setmedicalCauses(top10Data)
      }

      useEffect(() => {
        if(medicalCausesData) {
            formatTop10Data(medicalCausesData);
        }
      }, [medicalCausesData])


    return (
        <Card className={`mx-auto border-[1px] shadow-none overflow-hidden text-black w-full rounded-r-none`}>
            <div className="text-xl font-semibold p-6">{'Medical Causes'}</div>
            <BarChartComponent chartData={medicalCauses} />
        </Card>
    )
}

export default MedicalCausesComponent;