export const SUMMARY_CONFIG = [
    {
        title: 'Total Cases',
        key: 'inboundCasesCount',
        value: 0,
        bgColor: 'bg-[#FFECE9]',
        color: 'text-[#E9231D]',
    },
    {
        title: 'On the way to pick-up',
        key: 'dispatchedCasesCount',
        value: 0,
        color: 'text-[#7F56D9]',
        bgColor: 'bg-[#7F56D91A]',
    },
    {
        title: 'On the way to drop-off',
        key: 'pickedCasesCount',
        value: 0,
        color: 'text-[#DD2590]',
        bgColor: 'bg-[#DD25901A]',
    },
    {
        title: 'Completed',
        key: 'completedCasesCount',
        value: 0,
        bgColor: 'bg-[#F7F7F7]',
        color: 'text-[#161B1F]',
    },
    {
        title: 'Ambulance Availability',
        key: 'hospitalAmbAvailability',
        value: 0,
        bgColor: 'bg-[#DC68031A]',
        color: 'text-[#DC6803]',
    },
]

export const CASE_TYPE = [
    {
        type: 'unassigned',
        title: 'Unassigned'
    },
    {
        type: 'upcoming',
        title: 'Ongoing',
    },
    {
        type: 'completed',
        title: 'Completed',
    }
]

export const TRIAGE_TAG_COLORS = {
    immediate: 'bg-[#E9231D]',
    deceased: 'bg-[#161B1F]',
    delayed: 'bg-[#D9AB06]',
    minor: 'bg-[#008710]',
}

export const CASE_STATUS_OBJ = {
    dispatched: {
        title: 'On the way to pick-up',
        color: 'text-[#7F56D9]',
        bgColor: 'bg-[#7F56D91A]',
    },
    pickedup: {
        title: 'On the way to drop-off',
        color: 'text-[#DD2590]',
        bgColor: 'bg-[#DD25901A]',
    },
    completed: {
        title: 'Completed',
        color: 'text-[#008710]',
        bgColor: 'bg-[#0087101A]',
    },
}
