import { Button } from '@/components/custom/button'
import { Card, CardContent } from '@/components/ui/card'
import React, { useEffect, useRef, useState } from 'react'
import { cn } from '@/lib/utils'
import { TRIAGE_TAG_COLORS } from '@/pages/dashboard/dashboard/dashboard.constants'
import { necesseryDocsList } from '../../caseDetails.const'
import { useAppSelector } from '@/store/redux/hooks'
import { Icon } from '@iconify/react/dist/iconify.js'
import request from '@/api/request'
import { fetchReadSignedApi, getEpcrReportSignedUrl } from '@/hooks/api/apiConfig'
import UpdatePatientDetailsModal from '@/pages/case-creation/updatePatientDetailsModal';

interface PatientDetailsCard {
  caseData: any
}

interface ICards {
  title: string | JSX.Element
  styles?: any
  className?: string
  children: any
  alertClasses?: string
}

export function CardWithTitle({
  title,
  className,
  styles,
  children,
  alertClasses,
}: ICards) {
  return (
    <Card
      className={`m-1 overflow-hidden bg-transparent font-sans text-black ${className} ${alertClasses}`}
    >
      <div>
        <div className={`p-[1rem] `} style={{ ...styles }}>
          {title}
        </div>
        {children}
      </div>
    </Card>
  )
}

//box-shadow: 0px 0px 12px 0px #E9231D;

const InfoDoubleLine = ({
  className,
  label,
  info,
  onClick = () => { },
}: any) => {
  return (
    <div className={`m-1 text-left ${className}`} onClick={onClick}>
      <p className='text-sm font-normal capitalize text-gray-600'>
        {label}
      </p>
      <p className='text-sm font-semibold capitalize '>{info}</p>
    </div>
  )
}

export const PatientDetailsCard = ({ caseData }: PatientDetailsCard) => {
  const { clientList } = useAppSelector((app) => app.userStore)

  const [isUpdateDetailsOpen, setIsUpdateDetailsOpen] = useState(false);

  const toggleModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // closeAmbulanceAssignModal(e);
    // setIsAmbulanceAssignmentModalOpen((prev)=> false);
    setIsUpdateDetailsOpen((prev) => !prev)
};

  const accordionData = [
    {
      title: 'Other Informations',
      content: (
        <div>
          <div className='flex flex-wrap justify-between'>
            <InfoDoubleLine
              label={'Add-ons'}
              info={
                caseData?.addons?.length
                  ? caseData?.addons?.map((addon) => addon?.type).toString()
                  : '-'
              }
            />
            <InfoDoubleLine
              label={'Source'}
              className='text-right'
              info={
                clientList?.find(
                  (itm) => itm.clientId === caseData?.caseSource?.source
                )?.clientName || '-'

                // caseData?.caseSource?.platform
              }
            />
          </div>
          <div className='flex flex-wrap justify-between'>
            <InfoDoubleLine
              label={'Payment'}
              info={
                caseData?.billToClient ? 'Bill to Hospital' : 'Bill to Patient'
              }
            />
            {caseData?.billToClient && (
              <InfoDoubleLine
                className='text-right'
                label={'Total amount'}
                info={Number(caseData?.totalAmount) / 100}
              />
            )}
          </div>
          <hr className='my-2 h-px border border-[#E9EAEB] dark:bg-gray-700' />
          <div className='flex flex-wrap justify-between'>
            <InfoDoubleLine
              label={'Patient attendant'}
              info={caseData?.patientAttendantDetails?.name || 'NA'}
            />
            <InfoDoubleLine
              label={'Phone number'}
              className='text-right'
              info={caseData?.patientAttendantDetails?.mobile || 'NA'}
            />
          </div>
        </div>
      ),
    },
  ]
  return (
    <>
      <CardWithTitle
        title={
          <div className='flex items-center justify-between'>
            <div className='flex flex-1 justify-center'>
            <Icon icon='solar:user-id-line-duotone' width='24' height='24' />
            <div className='ml-3 text-sm font-semibold'> Patient Details </div>
            </div>
            {caseData?.overallStatus !== 'completed' &&  <div 
              className='flex items-center px-2 py-1 bg-white rounded-lg border border-[#BEC1C4] cursor-pointer'
              onClick={toggleModal}
            >
              <Icon icon='ix:pen' color='#6D747B' fontSize={20} />
              <div className='font-semibold text-sm pl-1'>{'Edit'}</div>
            </div>}
          </div>
        }
        className={'mb-5 overflow-scroll text-center'}
        styles={{
          backgroundColor: '#F7F7F7',
        }}
      >
        <CardContent className='py-5 '>
          <div className='grid grid-cols-2 sm:grid-cols-3'>
            <InfoDoubleLine
              label={'Patient name'}
              info={caseData?.patientDetails?.name}
            />
            <InfoDoubleLine
              label={'Phone number'}
              info={caseData?.patientDetails?.mobile ?? '-'}
            />
            <InfoDoubleLine label={'Case ID'} info={caseData?.caseId} />
            <InfoDoubleLine
              label={'Age'}
              info={`${caseData?.patientDetails?.age} years`}
            />
            <InfoDoubleLine
              label={'sex'}
              info={caseData?.patientDetails?.gender}
            />
            <InfoDoubleLine
              label={'Height'}
              info={
                !caseData?.patientDetails?.height?.feet
                  ? '-'
                  : `${caseData?.patientDetails?.height?.feet || '-'} . ${caseData?.patientDetails?.height?.inches || '-'} ft`
              }
            />
            <InfoDoubleLine
              label={'weight'}
              info={
                caseData?.patientDetails?.weight
                  ? `${caseData?.patientDetails?.weight} Kg`
                  : '-'
              }
            />
          </div>
          <div className='mt-2 flex flex-col'>
            <InfoDoubleLine
              label={'Triage tag'}
              info={
                <div
                  className={cn(
                    'rounded-2xl px-3 py-1 text-xs font-semibold text-black',
                    !caseData?.triageTag && 'px-0',
                    TRIAGE_TAG_COLORS?.[
                    (caseData.triageTag as string)?.toLowerCase()
                    ] &&
                    TRIAGE_TAG_COLORS?.[
                    (caseData.triageTag as string).toLowerCase()
                    ]
                  )}
                >
                  {caseData?.triageTag || '-'}
                </div>
              }
            />
            <InfoDoubleLine
              label={'Medical Symptoms'}
              info={caseData?.patientDetails?.medicalIssue?.reason?.toString()}
            />
          </div>
        </CardContent>
        <CardContent className='px-0 pb-0'>
          <Accordion accordionData={accordionData} />
        </CardContent>
      </CardWithTitle>
      {  <UpdatePatientDetailsModal isOpen={isUpdateDetailsOpen} toggleModal={toggleModal} orderData={caseData} /> }
    </>
  )
}

interface IDocuments {
  caseId: string
}

export const DocumentsCard = ({ caseId }: IDocuments) => {
  const [docs, setDocs] = useState({
    necesseryDocs: [],
    otherDocs: [],
  })


  const [epcrReportUrl, setEpcrReportUrl] = useState<any>(null);

  const segregateDocs = (documents: any) => {
    let necesseryDoc = []
    let otherDocs = []
    Object.keys(documents)?.forEach((documentType) => {
      if (necesseryDocsList.includes(documentType)) {
        necesseryDoc = [...necesseryDoc, ...documents[documentType]]
      } else {
        otherDocs = [...otherDocs, ...documents[documentType]]
      }
    })
    return {
      necesseryDocs: necesseryDoc,
      otherDocs: otherDocs,
    }
  }


  const fetchEpcrReportUrl = async () => {
    try {
      const responseData = await getEpcrReportSignedUrl(caseId);
      console.log(responseData);
      responseData?.data?.signedUrl && setEpcrReportUrl(responseData?.data?.signedUrl);
    }
    catch (err) {
      console.log(err);
    }
  }



  const getFormattedFileName = (fileName: any) => {

    let name = fileName?.split('/');
    name = name?.reverse();
    try{
      // Removing timestamp from the file name
      let splitedName = name?.[0]?.split('_');
      let fileFormate = splitedName[splitedName?.length-1].split('.');
      let formattedName = splitedName?.slice(0, splitedName?.length-1)?.join('_');
      let fileType = fileFormate[fileFormate?.length-1];
      return formattedName + '.' + fileType ?? '-';
    } catch (error){
      return name?.[0] ?? '-';
    }
  }

  const getDocuments = async () => {
    try {
      const docResponse = await request(
        'GET',
        `ers/v1/pub/cases/${caseId}/documents`
      )
        .then((data: any) => {
          return data?.data?.data?.documents
        })
        .catch((error) => {
          console.error('Error:', error)
        })
      let documentEntries = {}
      for (let [documentType, documents] of Object.entries(docResponse)) {
        const urlPromises = (documents as any)?.map(async (file: any) => {
          const signedUrl: any = await generateSignedUrl(file.fileName)
          return { ...file, signedUrl: signedUrl }
        })
        const signedFiles = await Promise.all(urlPromises)
        documentEntries[documentType] = signedFiles
      }
      setDocs(segregateDocs(documentEntries))
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const generateSignedUrl = async (fileName: string) => {
    return fetchReadSignedApi(caseId, fileName)
      .then((data: any) => {
        return data?.data?.data?.signedUrl
      })
      .catch((error) => {
        console.error('Error : ', error)
        return ''
      })
  }

  useEffect(() => {
    getDocuments();
    fetchEpcrReportUrl();
  }, [])

  const listRef = useRef(null)
  function scrollToBottom() {
    listRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [])

  const downloadFile = (file: any) => {
    if (file?.url) {
      fetch(file?.signedUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', file?.fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch((error) => console.error('Error downloading file:', error))
    }
  }

  return (
    <>
      <CardWithTitle
        title={
          <div className='flex justify-between items-center'>
            <div className='flex-1 flex justify-center'>
              <Icon icon='solar:documents-line-duotone' width='24' height='24' />
              <div className='ml-3 text-sm font-semibold'> Documents </div>
            </div>
            <div className=''>
              <Button size='sm' disabled={!epcrReportUrl}>
                <a href={epcrReportUrl} target='_blank'>
                  Download EPCR
                </a>
              </Button>
            </div>
          </div>
        }
        className={cn('overflow-scroll text-center text-lg')}
        styles={{
          backgroundColor: '#F7F7F7',
          marginBottom: '12px',
        }}
      >
        <CardContent className='py-5'>
          <div>
            <div>Necessary documents</div>
            {!docs?.necesseryDocs?.length && (
              <span className='text-sm'>No Documents available</span>
            )}
            {docs?.necesseryDocs?.map((document: any, index: number) => {
              return (
                <div
                  key={`${JSON.stringify(document)}+${index}`}
                  className='m-1 flex justify-between'
                >
                  <div className='py-2 text-sm w-[60%]'>{getFormattedFileName(document?.fileName)}</div>
                  <div>
                    <Button
                      className='mx-5'
                      size='sm'
                      onClick={() => downloadFile(document)}
                    >
                      Download
                    </Button>
                    <Button variant='secondary' size='sm'>
                      <a href={document?.signedUrl} target='_blank'>
                        View
                      </a>
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
          <hr className='my-2 h-[2px] border border-[#E9EAEB] dark:bg-gray-700' />
          <div>
            <div>Other documents</div>
            {!docs?.otherDocs?.length && (
              <span className='text-sm'>No Documents available</span>
            )}
            {docs?.otherDocs?.map((document: any, index: number) => {
              return (
                <div
                  key={`${JSON.stringify(document)}+${index}`}
                  className='m-1 flex justify-between'
                >
                  <div className='py-2 text-sm max-w-[60%]'>{getFormattedFileName(document?.fileName)}</div>
                  <div>
                    <Button
                      className='mx-5'
                      size='sm'
                      onClick={() => downloadFile(document)}
                    >
                      Download
                    </Button>
                    <Button variant='secondary' size='sm'>
                      <a href={document?.signedUrl} target='_blank'>
                        View
                      </a>
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>
        </CardContent>
      </CardWithTitle>
    </>
  )
}

export const Accordion = ({ accordionData }: any) => {
  const [openSection, setOpenSection] = useState<number>(0)

  const toggleSection = (sectionNumber: number) => {
    setOpenSection(openSection === sectionNumber ? null : sectionNumber)
  }

  return (
    <div id='accordion-collapse' data-accordion='collapse'>
      {accordionData.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <h2 id={`accordion-collapse-heading-${index + 1}`}>
            <button
              type='button'
              className={`flex w-full items-center justify-between border p-3 text-center font-medium text-gray-500 ${index === 0 && accordionData.length !== 1 ? 'rounded-t-xl border-b-0' : 'border-b-0'} ${openSection === index + 1 ? 'border-b-0' : ''} gap-3 border-gray-100 bg-gray-100 focus:ring-1 focus:ring-gray-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-800`}
              onClick={() => toggleSection(index + 1)}
              aria-expanded={openSection === index + 1}
              aria-controls={`accordion-collapse-body-${index + 1}`}
            >
              <div>{item.title}</div>
              <svg
                data-accordion-icon
                className={`h-3 w-3 ${openSection !== index + 1 ? 'rotate-180' : ''} shrink-0`}
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 10 6'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M9 5 5 1 1 5'
                />
              </svg>
            </button>
          </h2>
          <div
            id={`accordion-collapse-body-${index + 1}`}
            className={openSection === index + 1 ? '' : 'hidden'}
            aria-labelledby={`accordion-collapse-heading-${index + 1}`}
          >
            <div
              className={`border p-3 ${index === accordionData.length - 1 ? 'border-t-0' : 'border-b-0'} border-gray-200 dark:border-gray-700 ${index === accordionData.length - 1 ? '' : 'dark:bg-gray-900'}`}
            >
              {item.content}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
