export const topSectionTabs = {
    calls: {
        text: 'Calls',
        active: false,
    },
    trips: {
        text: 'Cases',
        active: false,
    },
    // responseTime: {
    //     text: 'Response Time',
    //     active: false,
    // },
    customerFeedback: {
        text: 'Customer Feedback',
        active: false,
    },
    geoMap: {
        text: 'Geo Map',
        active: false,
    },

}

export const incomingCallTabs: {[key: string]: any} = {
    totalCalls: {
        text: 'Total Calls',
        value: 800,
        percentage: 8.5
    },
    answeredCalls: {
        text: 'Answered Calls',
        value: 800,
        percentage: 8.5
    },
    answeredCallsInPct: {
        text: "Answered Call (%)",
        value: 0,
        percentage: 0,
    },
    ambCalls: {
        text: 'Ambulance Calls',
        value: 800,
        percentage: 8.5
    },
    // ambTrips: {
    //     text: 'Ambulance Trips (CC)',
    //     value: 0,
    //     percentage: 0
    // },
    // callToTripConversion: {
    //     text: 'Calls To Trips Conversion',
    //     value: 800,
    //     percentage: 8.5
    // },
}

export const tripTabs = {
    totalInbound: {
        text: 'Inbound',
        value: 800,
        percentage: 8.5
    },
    totalOutbound: {
        text: 'Outbound',
        value: 800,
        percentage: 8.5
    },
    // totalLamaDama: {
    //     text: 'LAMA/DAMA',
    //     value: 800,
    //     percentage: 8.5
    // },
    totalIntraBranch: {
        text: 'Intra-Branches',
        value: 800,
        percentage: 8.5
    },
    totalTrips: {
        text: 'Total Cases',
        value: 800,
        percentage: 8.5
    },
}

export const tripsOtherDataKeyMapping = {
    totalInbound: 'INBOUND',
    totalOutbound: 'OUTBOUND',
    totalLamaDama: 'LAMA/DAMA',
    totalIntraBranch: 'INTRA BRANCH',
    totalTrips: 'overAll',
}
export const tripsByPlatformKeyMapping = {
    totalInbound: 'INBOUND',
    totalOutbound: 'OUTBOUND',
    totalLamaDama: 'LAMA/DAMA',
    totalIntraBranch: 'INTRA BRANCH',
    totalTrips: 'overall',
}


export const responseTimeTabs = {
    callToWheelTime: {
        text: 'Call To Wheel',
        value: 800,
        percentage: 8.5
    },
    callToPickupTime: {
        text: 'Call To Pickup',
        value: 800,
        percentage: 8.5
    },
    bookToAssignmentTime: {
        text: 'Booking To Assignment Time',
        value: 800,
        percentage: 8.5
    },
    assignmentToWheelTime: {
        text: 'Assignment To Wheel Time',
        value: 800,
        percentage: 8.5
    },
}


export const reportTypeMapping = {
    calls: 'Calls Report',
    feedbacks: 'Feedback Report',
    trips: 'Cases Report',
    b2h: 'Bill To Hospital Report',
}
