import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchOrdersForClientId } from '../services/order.service'
import { IQueryClientId } from '@/utils/types'
import { NETWORK_STORE_KEY } from '@/store/redux/key'
import { updateSearchKeyword, updateSelectedSource } from '@/store/redux/orders/orderSlice'
import { fulfilledCasesStatus, ongoingCasesStatus, unassignedCasesStatus } from './order.const'
import { updateSelectedClientId } from '@/store/redux/user/userSlice'

export const fetchOngoingOrdersThunk = createAsyncThunk(
  NETWORK_STORE_KEY['@order/fetch-ongoing-cases'],
  async (payload: IQueryClientId) => {
    const data: any = await fetchOrdersForClientId(payload)
    const ongoingCases = data?.data?.filter((entry: any)=> ongoingCasesStatus.includes(entry?.overallStatus ?? ''));
    const fulfilledCases = data?.data?.filter((entry: any)=> fulfilledCasesStatus.includes(entry?.overallStatus ?? ''));
    const unassignedCases = data?.data?.filter((entry: any)=> unassignedCasesStatus.includes(entry?.overallStatus ?? ''));
    if(data?.data && data?.data?.length){
      return ({
        ongoing: ongoingCases,
        completed: fulfilledCases,
        unassigned: unassignedCases,
      })
    }
    else {
      return ({
        ongoing: [],
        completed: [],
        unassigned: [],
      })
    }
  }
)

export const fetchCaseHistoryThunk = createAsyncThunk(
  NETWORK_STORE_KEY['@order/fetch-cases-history'],
  async (payload: IQueryClientId, { dispatch }) => {
    const data: any = await fetchOrdersForClientId(payload)
    // dispatch(updateSelectedSource(payload.clientId))
    dispatch(updateSelectedClientId(payload.clientId))
    dispatch(updateSearchKeyword(''))

    const caseHistory = data?.data || []
    if (caseHistory && caseHistory.length) {
      return caseHistory?.map((item: any) => ({
        ...item,
        id: `${item?.caseId}-${item?.patientDetails?.mobile}`,
        patientMobile: item?.patientDetails?.mobile,
        patientAttenderMobile: item?.patientAttendantDetails?.mobile,
        patientName: item?.patientDetails?.name,
      }))
    } else {
      return []
    }
  }
)
