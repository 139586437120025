

import { Button } from "@/components/ui/button";
// import { Input } from "@/components/ui/input";
import {Input, Space, Modal} from 'antd';
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { memo, useEffect, useState } from "react";
import MedicalSymptoms from "./medicalSymptoms";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import "./caseCreation.css"
import { updateOrderDetails } from "@/hooks/api/apiConfig";
import { useNotification } from "@/components/ui/notificationProvider";
import Loader from "@/components/loader";
import useOrders from "@/api/hooks/useOrder";




const _UpdatePatientDetailsModal = ({isOpen, toggleModal, orderData, }) => {

    const [medicalIssue, setMedicalIssue] = useState(orderData?.patientDetails?.medicalIssue)
    const [selectedRadio, setSelectedRadio] = useState(orderData?.patientDetails?.gender);
    const handleRadioChange = (value: string) => {
        setSelectedRadio(value)
       }
    const {notify} = useNotification();
    const {updatePatientDetailsForOrder} = useOrders();

    const [apiLoading, setApiLoading] = useState(false);

    const [formData, setFormData] = useState({
        patientName:  orderData?.patientDetails?.name || "",
        phoneNumber: orderData?.patientDetails?.mobile || "",
        age: orderData?.patientDetails?.age,
        feet: orderData?.patientDetails?.height?.feet || "",
        inches: orderData?.patientDetails?.height?.inches || "",
        weight: orderData?.patientDetails?.weight || "",
        medicalConditions: medicalIssue?.reason
      });
          
      const isFormValid =
        formData.patientName.trim() &&
        formData.phoneNumber.trim() &&
        formData.age > 0
    
        
      useEffect(()=> {
        if(orderData?.patientDetails) {
          setMedicalIssue(orderData?.patientDetails?.medicalIssue);
        }
        return ()=> {
          setMedicalIssue([]);
        }
      }, [orderData])

      const handleInputChange = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const { id, value } = e.target;
        setFormData(  (prev) => { return { ...prev, [id]: value }});
      };
    
      const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        if (isFormValid) {
            const requestBody = {
                requestedFor: {
                    name: formData.patientName,
                    mobile: formData.phoneNumber,
                    age: Number(formData.age),
                    weight: Number(formData.weight),
                    height: {
                        feet: formData.feet,
                        inches: formData.inches,
                    },
                    gender: selectedRadio,
                    medicalIssue: {
                        reason: medicalIssue?.reason,
                    }
                }
              }

              try {
                setApiLoading(true);
                const response = await updateOrderDetails(orderData?.caseSource?.source, orderData.caseId, requestBody);
                updatePatientDetailsForOrder({patientDetails: requestBody.requestedFor, orderId: orderData.caseId })
                notify('success', '', 'Order updated successfully.')
                toggleModal(e);
              }
              catch (err) {
                setApiLoading(false);
                notify('error', '', 'Error updating order details.')
              }
              finally {
                setApiLoading(false);

              }
        }
      };


    return (
        <Modal
        open={isOpen}
        footer={null}
        onClose={toggleModal}
        onCancel={toggleModal}
        // onRequestClose={toggleModal}
        // title="Update Patient Details"
        // className="relative mx-auto my-auto bg-white w-[500px] p-4 rounded shadow-lg"
        // overlayClassName="fixed top-0 z-[100] left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
          <div className="pb-6">
        <div className="pb-2 text-lg font-medium">{'Update Patient Details'}</div>
        <Separator/>
        </div>
        <form className="space-y-4" onSubmit={handleFormSubmit}>
          <div>
            <Label htmlFor="patientName" className="mb-2 block">
              Patient Name <span className="text-red-500">*</span>
            </Label>
            <Input
            className="input-box"
              id="patientName"
              value={formData.patientName}
              onChange={handleInputChange}
              placeholder="patient Name"
              required
            />
          </div>
          <div>
            <Label htmlFor="phoneNumber" className="mb-2 block">
              Phone number <span className="text-red-500">*</span>
            </Label>
            <Input
                className="input-box"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            //   type="tel"
              placeholder="phone Number"
              required
            />
          </div>
          <div className="flex">
            <div className="w-full">
                <Label htmlFor="gender" className="mb-2 block text-sm font-medium">
                Gender <span className="text-red-500">*</span>
                </Label>
                <div className="w-full">
                <RadioGroup className="space-y-4" onValueChange={handleRadioChange} value={selectedRadio}>
                <div className="flex items-center ">
                    <div className="pr-2 flex items-center">
                        <RadioGroupItem value="Male" id="male" />
                        <Label htmlFor="male" className="pl-2 font-normal">Male</Label>
                    </div>
                    <div className="flex items-center pl-6">
                        <RadioGroupItem value="Female" id="female" />
                        <Label htmlFor="female" className="pl-2 text-sm font-normal">Female</Label>
                    </div>
                </div>
                </RadioGroup>
                </div>
                {/* <Input
                id="gender"
                value={formData?.gender || ""}
                onChange={handleInputChange}
                placeholder="gender"
                /> */}
            </div>
            <div className="pl-4 w-full">
                <Label htmlFor="age" className="mb-2 block">
                Age <span className="text-red-500">*</span>
                </Label>
                <Input
                className="input-box"
                addonAfter={"years"}
                id="age"
                type="number"
                value={formData?.age || ""}
                onChange={handleInputChange}
                placeholder="Enter patient age"
                />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2">
                <Label htmlFor="height" className="mb-2 block">
                Height <span className="text-red-500">*</span>
                </Label>
                <Space.Compact>
                    <Input className="w-1/3 input-box" id="feet"  placeholder="feet" type="number"
                        value={formData.feet}
                        onChange={handleInputChange}
                    />
                    <Input className="w-1/2 input-box" id="inches" placeholder="inches" type="number" addonAfter={'feet'}
                        onChange={handleInputChange}
                        value={formData.inches}
                    />
                </Space.Compact>
                {/* <Input
                id="age"
                value={formData?.age || ""}
                onChange={handleInputChange}
                placeholder="Enter patient age"
                /> */}
            </div>

            <div className="w-1/2 pl-4">
                <Label htmlFor="weight" className="mb-2 block">
                Weight <span className="text-red-500">*</span>
                </Label>
                <Input
                id="weight"
                className="input-box"
                type="number"
                addonAfter={'kg'}
                value={formData?.weight || ""}
                onChange={handleInputChange}
                placeholder="Weight"
                />
            </div>
          </div>
          <div>
          <Label htmlFor="medicalCondition" className="mb-2 block">
              Medical condition <span className="text-red-500">*</span>
            </Label>
            {/* <Input
              id="medicalCondition"
              value={formData?.medicalCondition || ""}
              onChange={handleInputChange}
              placeholder="Enter medical condition"
            /> */}
            <MedicalSymptoms
            onDone={(value)=> console.log(value)}
            value={medicalIssue}
            setValue={setMedicalIssue}
            />
          </div>


          <div className="pt-4 flex justify-end">
            <Button type="submit" className="w-[25%]" disabled={!isFormValid}>
              { apiLoading ? <Loader /> : "Save"}
            </Button>
          </div>
        </form>
        </Modal>
    )

}
const UpdatePatientDetailsModal = memo(_UpdatePatientDetailsModal);
export default _UpdatePatientDetailsModal;