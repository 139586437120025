export const userInitialState = {
  clientList: [],
  clientMap: {},
  partnerListMapping: [],
  selectedClientId: '',
  user: {
    userId: '',
    role: '',
    email: '',
    eligibleRoles: [],
    fullName: '',
    primaryPhoneNo: '',
    altPhoneNo: null,
    locality: null,
    district: null,
    cityId: '',
    countryId: '',
    pincode: '',
    aadhaarNo: '',
    panNo: '',
    clientId: 'xxxxxxx',
    dedicatedToMasterId: null,
    dedicatedToClientIds: [],
    status: '',
    createdAt: '',
    createdBy: '**********',
    updatedAt: '',
    updatedBy: '**********',
  },
  status: {
    verifyOtpToken: 'idle',
    fetchUserInformation: 'idle',

  },
  errors: {
    verifyOtpToken: null,
    fetchUserInformation: null,
  },
}
