import Icon from "@/components/global/icon/Icon";
import { Skeleton } from "@/components/ui/skeleton"
import "./analyticsDashboard.css";
import { LineChartComponent } from "./chart-components/lineChart";
import BarChartComponent from "./chart-components/barChart";
import { PieChartComponent } from "./chart-components/pieChart";
import { topSectionTabs } from "./analyticsDashboard.constants";
import IncomingCallsComponent from "./components/incomingCalls.Component";
import TripsComponent from "./components/trips.component";
import AnsweredCallComponent from "./components/answeredCalls.component";
import TimeMetricsComponent from "./components/timeMetrics";
import RevenueMetricsComponent from "./components/revenueMetrics.component";
import { useEffect, useRef, useState } from "react";
import MedicalCausesComponent from "./components/medicalCauses";
import TripsByPlatform from "./components/tripsByPlatform";
import ResponseTimeComponent from "./components/responseTime.component";
import CustomerFeedbackComponent from "./components/customerFeedback.component";
import { getAnalyticsDashboardData, getPartnerClientList } from "@/hooks/api/apiConfig";
import GeoHeatmap from "./components/geoMap.component";
import Loader from "@/components/loader";
import  { DatePickerWithRange } from "./headerComponents/dateRangeSelector";
import useAnalytics from "@/api/hooks/useAnalytics";
import useUser from "@/api/hooks/useUser";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import DownloadReport from "./components/downloadReport";
import PatientDemograph from "./components/patientDemograph";
import {Empty} from "antd";
import { analyticsDashboardAccessSources } from "@/globalConstants";




const AnalyticsPageDashboard = () => {

    const [activeTab, setActiveTab] = useState<any>(null);
    const sectionRefs = useRef<Record<string, HTMLElement | null>>({});
    const [dashbaordData, setDashboardData] = useState<any>();
    const [noDataFound, setNoDataFound] = useState(false);
    const [topSections, setTopSections] = useState(topSectionTabs);

    const {analyticsDateRange} = useAnalytics();
    const {user: {clientId} } = useUser();

    const [apiLoading, setApiLoading] = useState(false);


    const fetchAnalyticsDashboardData = async (startDate: number, endDate: number) => {
        setApiLoading(true);
        try {
            // const partnerListData = await getPartnerClientList()
            const data = await getAnalyticsDashboardData(clientId, startDate, endDate);
            setApiLoading(false);
            setDashboardData(data?.data);
            setTopSections((prev)=> {
                return {
                    calls: {
                        text: 'Calls',
                        active: !!data?.data?.calls,
                    },
                    trips: {
                        text: 'Cases',
                        active: !!data?.data?.trips,
                    },
                    // responseTime: {
                    //     text: 'Response Time',
                    //     active: !!data?.data?.trips?.responseTimeMetrics,
                    // },
                    customerFeedback: {
                        text: 'Customer Feedback',
                        active: !!data?.data?.feedback,
                    },
                    geoMap: {
                        text: 'Geo Map',
                        active: !!data?.data?.trips?.demographicsMetrics,
                    }
                }
            })
            setNoDataFound(false);
            // console.log('data------>', dashbaordData);
        }
        catch (err) {
            setDashboardData({});
            setNoDataFound(true);
        }
        finally {
            setApiLoading(false);
        }
    }


    const createHeatmapArray = (latitudes: number[], longitudes: number[]): any[] => {
        if (latitudes.length !== longitudes.length) {
          throw new Error("Latitudes and longitudes arrays must have the same length.");
        }
      
        return latitudes.map((lat, index) => ( {lat: lat, lng: longitudes[index]}));
      };


    useEffect(() => {
        fetchAnalyticsDashboardData(analyticsDateRange.from, analyticsDateRange.to);
    }, [analyticsDateRange.from, analyticsDateRange.to]);
    

    
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setActiveTab(entry.target.id);
              }
            });
          },
          {
            root: null, // Viewport as root
            rootMargin: "0px", // Adjust based on desired visibility
            threshold: .4,
          }
        );
    
        // Observe each section
        Object.values(sectionRefs.current).forEach((section) => {
          if (section) observer.observe(section);
        });
    
        return () => observer.disconnect();
      }, [apiLoading]);


      const handleTabClick = (id: string) => {
        const targetSection = sectionRefs.current[id];
        if (targetSection) {
            const topOffset = 150; // Offset for your fixed header or margin adjustments
            const sectionTop = targetSection.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: sectionTop - topOffset, behavior: "smooth" });
          }
      };

      
    function SkeletonCard() {
        return (
            <div className="flex items-center space-x-4">
            <Skeleton className="h-12 w-12 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        )
    }


    // if(analyticsDashboardAccessSources.includes(clientId)) {

    // }


    return (
        <div className="bg-[#F7F7F7]">
            <div className="fixed w-full z-20">
                <div className="h-14 top-section flex items-center pl-4 bg-white w-full justify-between">
                    <div className='flex items-center justify-center h-auto w-auto'>
                        <img src="/images/dashboard-logo.svg" />
                        {/* <Icon color={'#E9231D'} icon='material-symbols:dashboard-outline' fontSize={24} /> */}
                        <div className='ml-2 text-xl font-semibold'>Dashboard</div>
                    </div>
                    <div className="px-5 pt-1 flex items-center">
                        <DownloadReport calls={!!dashbaordData?.calls} trips={!!dashbaordData?.trips} feedbacks={!!dashbaordData?.feedback} b2h={!!dashbaordData?.bth}/>
                        <DatePickerWithRange />
                    </div>
                </div>
                {(<div className="top-section flex items-center pl-4 bg-[#161B1F] w-full justify-end text-white">
                    {!noDataFound && <div className='flex items-center justify-center h-auto w-full cursor-pointer'>
                        {Object.entries(topSections).filter((entry)=> entry[1].active).map((entry) => {
                                return (
                                    <div
                                        className={`${entry[0] === activeTab ? 'font-semibold active-tab text-black' : 'font-base'} px-3 text-sm h-auto pt-2 pb-1`}
                                        onClick={() => handleTabClick(entry[0])}
                                    >
                                        {entry[1].text}
                                    </div>
                                )
                        })}
                    </div>}
                    <div className="text-xs italic text-right pr-5 w-[110px] flex-end flex justify-end">
                    Last updated 10 mins ago
                    </div>
                </div>) }
            </div>
            {noDataFound && (
                <div className="flex h-full justify-center items-center w-full p-48">
                    <Empty description="No data found for this source" />
                </div>
            )}
            {apiLoading 
            ? 
            <div className='fixed z-1000 grid h-full w-full place-content-center bg-pink bg-opacity-50'>
            <Loader />
            {/* <Skeleton /> */}
          </div>
            : 
            (
                <div className="max-w-[1280px] m-auto ">
                <div className="flex flex-col">
                <div className="pt-28"></div>
                    {dashbaordData?.calls && <div
                        key={'calls'}
                        id={'calls'}
                        ref={(el) => (sectionRefs.current['calls'] = el)}>
                        <div
                            className="p-4">
                            <IncomingCallsComponent callsData={dashbaordData?.calls?.callsMetrics} />
                        </div>
                        <div
                            className="px-4 pb-4 pt-0 flex"
                        // key={'calls'}
                        // id={'calls'}
                        // ref={(el) => (sectionRefs.current['calls'] = el)}
                        >
                            <AnsweredCallComponent answeredCallsData={dashbaordData?.calls?.callsDisposition} />
                            <div className="pl-5 w-[25%]">
                                <TimeMetricsComponent timeMetricsData={dashbaordData?.calls?.timeMetrics}/>
                            </div>
                        </div>
                    </div>}
                    {dashbaordData?.trips && <div
                        className="px-4"
                        key={'trips'}
                        id={'trips'}
                        ref={(el) => (sectionRefs.current['trips'] = el)}
                    >
                        <TripsComponent 
                            tripsData={dashbaordData?.trips?.tripsMetrics} 
                            medicalCausesData={dashbaordData?.trips?.medicalCausesMetrics} 
                            tripsByPlatform={dashbaordData?.trips?.tripsByPlatformMetrics}
                        />
                    </div>}
                    {<div className="p-4 pt-0">
                        <RevenueMetricsComponent revenueData ={dashbaordData?.revenue} bthData={dashbaordData?.bth} redInbound={dashbaordData?.trips?.redInboundMetrics}/>
                    </div>}
                    {/* {dashbaordData?.trips?.responseTimeMetrics && <div
                        className="p-4 pt-0"
                        key={'responseTime'}
                        id={'responseTime'}
                        ref={(el) => (sectionRefs.current['responseTime'] = el)}
                    >
                        <ResponseTimeComponent responseTimeData={dashbaordData?.trips?.responseTimeMetrics} />
                    </div>} */}
                    {dashbaordData?.feedback && <div
                        className="p-4 pt-0"
                        key={'customerFeedback'}
                        id={'customerFeedback'}
                        ref={(el) => (sectionRefs.current['customerFeedback'] = el)}
                    >
                       {dashbaordData?.feedback && <CustomerFeedbackComponent feedbackData={dashbaordData?.feedback}/>}
                    </div>}
                    {dashbaordData?.trips?.demographicsMetrics && <>
                    <div
                        className="p-4 pt-0"
                        key={'geoMap'}
                        id={'geoMap'}
                        ref={(el) => (sectionRefs.current['geoMap'] = el)}
                    >
                       {dashbaordData?.trips && dashbaordData?.trips?.demographicsMetrics?.pickupLatitudes?.length > 0 && <GeoHeatmap geoData={createHeatmapArray(dashbaordData?.trips?.demographicsMetrics?.pickupLatitudes, dashbaordData?.trips?.demographicsMetrics?.pickupLongitudes)}/>}
                    </div>
                    <div
                        className="p-4 pt-0"
                        // key={'geoMap'}
                        // id={'geoMap'}
                        // ref={(el) => (sectionRefs.current['geoMap'] = el)}
                    >
                       {dashbaordData?.feedback && <PatientDemograph patientData={
                        {
                            gender: dashbaordData?.trips?.demographicsMetrics?.genderDistribution,
                            age: dashbaordData?.trips?.demographicsMetrics?.ageDistribution
                        }
                        }/>}
                    </div>
                    </>}
                </div>
                </div>
            )
            }

        </div>
    )

}

export default AnalyticsPageDashboard;

