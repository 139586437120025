import { axiosWrapper } from './axiosWrapper'

export const getOrdersSummary = async (source: string, params: string) => {
  const response = await axiosWrapper.get(
    `/ers/v1/clients/${source}/cases/summary?${params}&orderClassificationType=INBOUND`
  )
  return response.data
}

export const fetchFleetsBySourceId = async (souceId: string) => {
  const response = await axiosWrapper.get(`/ers/v1/clients/${souceId}/fleets`)
  return response.data
}

export const getActivityLogsByCaseId = async (caseId: string) => {
  const response = await axiosWrapper.get(`/ers/v1/logs/cases/${caseId}`)
  return response.data
}

export const fetchReadSignedApi = async (channel_id, fileName) => {
  const response = await axiosWrapper.get(
    `/ers/v1/fru/v1/fs/d/read/case/${channel_id}/file/${fileName}`
  )
  return response
}

export const getEpcrReportSignedUrl = async (orderId: string) => {
  const response = await axiosWrapper.get(
    `/ers/v1/pub/cases/${orderId}/epcr`
  )
  return response.data;
}


export const getAnalyticsDashboardData = async (clientId: string, startDate: number, endDate: number, ) => {
  const response = await axiosWrapper.get(`ers/v1/clients/${clientId}/analytics?reporttype=all&startDate=${startDate}&endDate=${endDate}&live`);
  return response.data;
}


export const fetchDownReportSignedUrl = async (clientId: string,  requestBody: any) => {
  const response = await axiosWrapper.post(`ers/v1/clients/${clientId}/analytics-reports`, requestBody);
  return response.data;
}


export const createEmergencyCase = async (clientId: string, requestBoay: any) => {
  const response = await axiosWrapper.post(`ers/v1/clients/${clientId}/cases`, requestBoay)
  return response.data;
}

export const getNearbyVehicles = async (clientId: string, orderId: string, params: string) => {
  const response = await axiosWrapper.get(`ers/v1/clients/${clientId}/cases/${orderId}/nearby/fleets?${params}`);
  return response.data;
}

export const updateOrderDetails = async (clientId: string, orderId: string, requestBody: any) => {
  const response = await axiosWrapper.patch(`ers/v1/clients/${clientId}/cases/${orderId}`, requestBody);
  return response.data;
}

export const cancelOrder = async (clientId: string, orderId:string, requestBody: any) => {
  const response = await axiosWrapper.patch(`ers/v1/clients/${clientId}/cases/${orderId}/cancel`,requestBody);
  return response.data;
}

export const triggerManualDispatch = async ( orderId:string, clientId: string, requestBody: any) => {
  const response = await axiosWrapper.patch(`ers/v1/clients/${clientId}/cases/${orderId}/emergency/manual-dispatch`, requestBody);
  return response.data;
}

export const getPartnerClientList = async () => {
  const response = await axiosWrapper.get(`ers/v1/clients/list`);
  return response.data;
}