export const searchRadiusConfig = [
    {
        label: '10 km',
        value: '10000'
    },
    {
        label: '50 km',
        value: '50000'
    },
    {
        label: '100 km',
        value: '100000'
    },
    {
        label: '200 km',
        value: '200000'
    },
    {
        label: '400 km',
        value: '400000'
    }
]


export const RED_SYMPTOMS: any = [
    'Shortness of breath (rapid breathing)',
    'Chest pain',
    'discomfort in chest',
    'Dizziness',
    'coughing (sometimes with blood)',
    'Fatigue (Loss of Energy)',
    'Irregular heartbeat',
    'pain (in shoulders-arms-neck-jaw-back)',
    'High Blood Pressure',
    'Heart Attack/Cardiac arrest',
    'Fainting',
    'Heart Problems',
    'Cardiology',
    'Swelling(near mouth)',
    'Severe Pain(near mouth)',
    'crack or break in teeth',
    'Blood loss(near mouth)',
    'bluish / Red skin color ( discoloration)',
    'dry cracked skin',
    'History of diabetes',
    'confusion',
    'Low Blood Sugar',
    'palpitation (rapid heart beat)',
    'Diabetes',
    'Sweating',
    'swollen legs',
    'Unconscious',
    'Low Blood Pressure',
    'swelling of face and throat',
    'tongue swelling',
    'swollen lymph nodes',
    'Headache',
    'facial pain',
    'nasal congestion',
    'Ear pain',
    'Difficulty hearing',
    'Lump in Neck',
    'ENT (Ear-Nose-Throat)',
    'Diarrhea / LOOSE STOOLS',
    'Head & Neck Surgery',
    'bruising',
    'Heart Failure',
    'Abdominal pain(especially on upper side)',
    'Pale/Blue Skin (Cynosis)',
    'Abdominal pain (especially on lower side)',
    'weakness',
    'Burning stomach pain',
    'Acidity',
    'Gas',
    'bloating',
    'Jaundice',
    'vomiting blood',
    'Spleen Injury',
    'Liver problems',
    'Pancreatitis',
    'Appendicitis',
    'Hiccups ( Continuos)',
    'Stomach Ulcer / Gastric Ulcer',
    'Stomach Pain',
    'Abdominal pain',
    'Severe Toothache',
    'constipation',
    'Red swollen and painful area of skin that is warm and tender to the touch',
    'ulcer in foot/puss discharge from foot',
    'foul smell from foot',
    'Bulge in the abdomen or groin(pelvic region)',
    'pain at the site of hernia',
    'nipple discharge',
    'Post Surgical Complications',
    'Bleeding',
    'Hernia',
    'Crush injury',
    'Open fractures and major dislocations',
    'Assault / Violence',
    'Accident',
    'History of cancer',
    'Mouth sores',
    'Lump in the neck',
    'Voice hoarseness',
    'neck pain',
    'voice changes',
    'History of liver disease',
    'yellowish skin',
    'bad taste in the mouth',
    'Abdominal swelling',
    'Fluid filled in abdomen',
    'sensitivity in teeth',
    'Dengue/Malaria',
    'inability to urinate',
    'swelling in affected region',
    'Tremors(shaking of hands)',
    'Dislocated jaw',
    'Coughing',
    'History of exposure to poison/toxin',
    'chills',
    'itching',
    'History of burn',
    'blurred vision',
    'thickened nails',
    'frequent infections',
    'High blood sugar',
    'Nausea',
    'Internal Medicine',
    'Covid-19',
    'Electrical Injury',
    'Poisoning',
    'Snake bite',
    'Typhoid',
    'Allergy',
    'Chikenpox/Measles',
    'Asthma',
    'Multiple Organ Failure',
    'Chikungunya',
    'Viral',
    'History of organ transplant',
    'pain at transplant site',
    'flu-like symptoms after transplant',
    'Transplant Region Swelling',
    'Blisters (fluid filled bubble on skin)',
    'Organ Transplant',
    'Blood In Urine',
    'Burning Sensation while Urination',
    'History of kidney disease',
    'frequent urination',
    'Dialysis',
    'Urinary Problems',
    'Kidney Problems',
    'Bladder Problems',
    'Nephrology',
    'numbness (loss of sensation)',
    'sensitivity to light',
    'visual disturbances',
    'stiff neck',
    'trouble speaking',
    'Convulsions (uncontrolled shaking of body)',
    'Neurosurgery',
    'Stroke',
    'Bleeding from Head',
    'Slip Disc',
    'Fits',
    'Neurology',
    'Head Injury',
    'Memory Loss',
    'Unable To Walk',
    'Paralysis',
    'Brain Problems',
    'Siezures',
    'History of pregnancy',
    'Strong & regular contractions',
    'Foot pain and swelling',
    'vaginal discharge',
    'pain in abdomen and lower back',
    'vaginal bleeding',
    'Delivery / Child Birth / Labor',
    'Gynaecology(Pregnancy Related)',
    'Postpartum (after delivery) Complications',
    'blood in stool',
    'weight loss',
    'Lump in the breast',
    'changes in breast shape',
    'redness',
    'bleeding gums',
    'Continuous coughing',
    'Oncology/Cancer',
    'Sudden vision loss',
    'eye pain',
    'discharge from eye',
    'Irritation in eye',
    'redness of eye',
    'watery eyes',
    'double vision',
    'eye swelling',
    'Ophthalmology',
    'Orthopaedics',
    'History of fall/trauma',
    'inability to bear weight',
    'Severe pain in affected region',
    'redness in affected region',
    'joint pain / bone pain',
    'Hip Fracture',
    'Fracture / Broken bone',
    'Knee Surgery / Bone surgery / Joint surgery',
    'Arthritis / Arthralgia',
    'History of thyroid',
    'Vomiting / PUKING',
    'dehydration',
    'Difficulty breathing',
    'Sudden severe testicular pain',
    'Pediatrics',
    'no tears when crying',
    'sunken eyes',
    'chest tightness',
    'Chest Burn / Heart burn',
    'agitation(extreme anger)',
    'Rehabilitation Therapies',
    'Child Cry',
    'Excessive thirst',
    'Wheezing(whistling sound while breathing)',
    'Fits/Seizures',
    'Pain',
    'Child/children',
    'Neo-Natal/New Born Babies',
    'History of injury',
    'Physiotherapy',
    'Deep red or white skin',
    'Cut in body part',
    'severe bleeding from cut',
    'History of facial trauma',
    'bleeding(from face)',
    'Intense fear',
    'delusions',
    'disorganized thinking',
    'History of recent delivery',
    'Thoughts of harming others and self',
    'severe depression',
    'History of alcohol consumption',
    'violent behaviour',
    'loss of reasoning',
    'Seizures',
    'psychosis',
    'hallucination',
    'Choking sensation',
    'throat tightness',
    'Acts/thoughts of self-harm',
    'hopelessness',
    'Addiction',
    'Anxiety/Panic Attack',
    'Choking',
    'Acting Crazy',
    'Psychiatry',
    'coughing with phlegm',
    'coughing blood',
    'Chest',
    'Lung infection',
    'Pulmonology',
    'Tuberculosis',
    'Drowning',
    'Water Besides Lungs/ plural effusion',
    'Pneumonia',
    'Joint pain',
    'swelling near joints',
    'stiffness of joints',
    'limited body movement',
    'redness near joints',
    'Severe back pain',
    'weakness in legs or arms',
    'difficulty walking',
    'Incoordination',
    'Spine Problems',
    'Spine Surgery',
    'History of sports trauma',
    'Sports Medicine',
    'Severe pain (in back or side of body)',
    'Sore throat',
    'difficulty swallowing',
    'fluid discharge from ear',
    'Fever',
    'Painful urination',
    'Kidney Stone',
    'Scrotum Problem',
    'Scrotal Swelling/ Bleeding / Injury',
    'Follow-Up Care',
    'Diagnosis / Medical Examination',
    'Drug Overdose',
    'nose bleed',
    'Bedridden Patient',
    'Cancer Screening',
    'Chemotherapy Transport',
    'Radiation Therapy Transport',
    'Pregnancy-Related Complications',
    'Infectious Diseases (e.g. Flu - COVID 19)',
    'Psychiatric Emergency (e.g. Suicide Attempt)',
    'Behavioral Health Transport (e.g. Schizophrenia)',
    'Pediatric Emergencies',
    'Neonatal Transport (Child < 1 month age )',
    'Hyperthermia (Heat Stroke)',
    'Migraine',
    'Skin rashes',
    'Animal Bite',
    'Injury from violence',
    'Suicide Attempt',
    'Burn',
    'Dental Appointment',
    'Hypothermia',
    'Transport for Surgical Follow-Up',
    'Transport for Medical Imaging (e.g. MRI - CT Scan)',
    'Transport for Physical Therapy',
    'Transport for Occupational Therapy',
    'Post-Surgical Complications (e.g. Infection)',
    'Transport for Blood Transfusion',
    'Transport for Dead Body',
    'Road Traffic Accident (RTA)',
    'Not Breathing',
    'No Pulse',
    'Not Responding',
    'Liver Transplant',
    'Kidney Transplant',
    'Lung Transplant',
  ]

  export const bookingCancellationReasons = [
    "Vehicle delay",
    "Patient Expired",
    "Went in Own Vehicle/Went in cab or Auto",
    "Competitor Serviced/Went in Private ambulance /Went to another Hospital/Went in 108",
    "Customer not responding",
    "Price issue",
    "Ambulance not available",
    "Equipment/Helper/O2/EMT Not available",
    "Customer requirement changed",
    "Duplicate Booking",
    "Test Booking",
    "Went in Hospital vehicle",
    "Union issue",
    "No Info Provided by the Customer",
    "Bed not available/Treatement Not Avaialble in the Hospital",
    "Customer requirement postponed",
    "Enquired By Mistake",
    "Fake Lead",
    "Patient stable at the Home/Hospital",
    "Patient is not stable not fit to travel",
    "Appointment Cancelled/Postponed/Doctor Not Available",
    "Appointment Call - Non-Ambulance Query Call",
    "AC not working",
    "Vehicle Hygiene Issues",
    "No Tracking Details Available",
    "False Commitment by the Partner",
    "Driver Not Responding",
    "Night time - Partner not Responding",
    "Vehicle on Personal Trip",
    "BHMS Doctor Available instead of MBBS",
    "Incubator not available",
    "Unprofessional and Driver Rude Behaviour",
    "Discharge Postponded",
    "Admitted in the same hospital",
    "Cremation at the same place",
    "Hospital Emergency Room (ER) Denied ",
    "Unable to Connect to Hospital Emergency Room (ER) ",
    "Driver Couldn't find the pickup location",
  ]