"use client"

import { TrendingUp } from "lucide-react"
import { LabelList, Pie, PieChart } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"

export function PieChartComponent({data, legendFlexView =false}: any) {


  const chartData = data?.map((item: any) => ({
    label: item.label,
    value: item.value,
    percentage: item.percentage,
    fill: item.fill,
  }));

  // Dynamic config generation
  const chartConfig = data?.reduce((acc: any, item: any, index: number) => {
    const colorVariable = `--chart-${index + 1}`;
    acc[item.label] = {
      label: item.label,
      value: item.value,
      percentage: item.percentage,
      color: `hsl(var(${colorVariable}))`,
    };
    return acc;
  }, {});

  return (
    <CardContent className="pb-0 flex justify-center w-full h-full">
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square h-full w-full"
      >
        <PieChart className="flex">
          <ChartTooltip
            content={<ChartTooltipContent nameKey="value" hideLabel />}
          />
          <Pie data={chartData} dataKey="value"  cx="50%" cy="50%" outerRadius="100%">
            <LabelList
              dataKey="label"
              className="fill-background text-xs font-semibold"
              stroke="none"
              fontSize={12}
              formatter={(value: any) => `${ !legendFlexView ? chartConfig[value]?.value : ""} (${chartConfig[value]?.percentage}%)`}
            />
          </Pie>
          <ChartLegend
            content={<ChartLegendContent nameKey="label" />}
            className={`flex-wrap gap-2 ${!legendFlexView ? 'flex-col' : ''}  [&>*]:basis-1/4 [&>*]:justify-center pb-10`}
          />
        </PieChart>
      </ChartContainer>
    </CardContent>
  )
}

{/* <CardContent className="pb-0">
  <ChartContainer config={chartConfig} className="mx-auto max-w-[400px] w-full">
    <PieChart width={400} height={400} className="mx-auto">
      <ChartTooltip content={<ChartTooltipContent nameKey="value" hideLabel />} />
      <Pie data={chartData} dataKey="value" cx="50%" cy="50%" outerRadius="80%">
        <LabelList
          dataKey="label"
          className="fill-background"
          stroke="none"
          fontSize={12}
          formatter={(value: any) =>
            `${chartConfig[value]?.value} (${chartConfig[value]?.percentage}%)`
          }
        />
      </Pie>
      <ChartLegend
        content={<ChartLegendContent nameKey="label" />}
        className="flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center pt-10"
      />
    </PieChart>
  </ChartContainer>
</CardContent> */}
