import useOrders from '@/api/hooks/useOrder'
import useEvents from '@/api/hooks/useEvents'
import { Separator } from '@/components/ui/separator';
import { cn } from '@/utils/classNameUtil';
import { CASE_STATUS_OBJ, TRIAGE_TAG_COLORS } from '../dashboard.constants';
import Icon from '@/components/global/icon/Icon';
import { useState } from 'react';
import UpdatePatientDetailsModal from '@/pages/case-creation/updatePatientDetailsModal';
import AssignAmbulanceModal from '@/pages/case-creation/vehicle-assignment/vehicleAssignment';


const UnassignedCaseCard : React.FC<any>= (caseProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isAmbulanceAssignmentModalOpen, setIsAmbulanceAssignmentModalOpen] = useState(false);

    const toggleModal = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        closeAmbulanceAssignModal(e);
        // setIsAmbulanceAssignmentModalOpen((prev)=> false);
        setIsOpen((prev) => !prev)
    };


    const {caseId} = caseProps;

    const { updateSelectedView } = useOrders()
    const { updateGlobalReadForOrder } = useEvents()

    const handleOrderSelect = (orderId: string) => {
          updateSelectedView(orderId);
          updateGlobalReadForOrder(orderId, false);
      }


      const handleCardClick  = (e: any) => {
        // e.preventDefault();
        // e.stopPropagation(); 
        setIsAmbulanceAssignmentModalOpen(true);
      }

      const closeAmbulanceAssignModal = (e: any) => {
        e.preventDefault();
        e.stopPropagation(); 
        setIsAmbulanceAssignmentModalOpen((prev)=> false);
      }


    return (
        <div
        className='mb-4 rounded-xl shadow h-44 cursor-pointer'
        onClick={handleCardClick}
      >
        <div className='flex justify-between rounded-t-xl bg-[#F7F7F7] p-2'>
          <div className='flex-1 flex justify-center'>
            Case ID:  <span className='font-semibold text-black'>{caseId}</span>
          </div>
          <div 
            className='flex items-center px-2 py-1 bg-white mx-4 rounded-lg border border-[#BEC1C4] cursor-pointer'
            onClick={toggleModal}
          >
            <Icon icon='ix:pen' color='#6D747B' fontSize={20} />
            <div className='font-semibold text-sm pl-1'>{'Edit'}</div>
          </div>
        </div>
        <div className='flex w-full flex-row justify-between p-5'>
          <div className= 'px-2 w-full'>
            <div className='flex justify-between'>
              <div className='mb-2'>
                <div className='text-sm text-[#5B6169] mb-1'>Contact person name</div>
                <div className='text-xs font-semibold'>
                  {caseProps?.patientDetails?.name ? caseProps?.patientDetails?.name : '-'}
                </div>
              </div>
              <div className='mb-2'>
                <div className='text-sm text-[#5B6169] mb-1'>Mobile Number</div>
                <div className='text-xs font-semibold capitalize'>
                  {caseProps?.patientAttendantDetails?.mobile ? '+91-' +caseProps?.patientAttendantDetails?.mobile : '-'}
                </div>
              </div>
              <div className='mb-2'>
                <div className='text-sm text-[#5B6169] mb-1'>Created by</div>
                <div className='text-xs font-semibold capitalize'>
                  {caseProps?.generatedBy ? caseProps?.generatedBy : '-'}
                </div>
              </div>
              <div className='mb-2'>
                <div className='text-sm text-[#5B6169] mb-1'>Source</div>
                <div className='text-xs font-semibold capitalize'>
                  { '-'}
                </div>
              </div>
            </div>
            {/* <Separator className='bg-black'/> */}
            <div className='flex '>
                <div className='mb-2 w-1/2'>
                    <div className='text-sm  text-[#5B6169] mb-1'>Medical symptoms</div>
                    <div className='text-xs font-semibold'>
                        {caseProps?.patientDetails?.medicalIssue?.reason ? caseProps?.patientDetails?.medicalIssue?.reason?.toString() : '-'}
                    </div>
                </div>
                <div className='mb-2 w-1/2'>
                    <div className='text-sm  text-[#5B6169] mb-1'>Pickup address</div>
                    <div className='text-xs font-semibold'>
                        {caseProps?.waypoints?.[0]?.address?.location ? caseProps?.waypoints?.[0]?.address?.location : '-'}
                    </div>
                </div>
            </div>
          </div>
          <UpdatePatientDetailsModal isOpen={isOpen} toggleModal={toggleModal} orderData={{...caseProps}}/>
         {isAmbulanceAssignmentModalOpen && !isOpen && <AssignAmbulanceModal isOpen={isAmbulanceAssignmentModalOpen} setIsOpen={closeAmbulanceAssignModal} orderData={caseProps} /> }
        </div>
      </div>
    )

}
export default UnassignedCaseCard;