import { Card } from "@/components/ui/card"
import MetricsCard from "./metricsCard"
import { Separator } from "@/components/ui/separator"
import Icon from "@/components/global/icon/Icon"


const CustomerFeedbackComponent = ({feedbackData}: any) => {

    return (
        <Card
        className={`mx-auto mt-2 border-[1px] overflow-hidden text-black shadow-none`}
        >
            <div className="text-xl font-semibold p-6">{'Customer Feedback'}</div>
                    <div className="flex">
            <div className="w-full">
                <MetricsCard title="Total Feedback Received" value={feedbackData?.totalFeedbackCountCurr} percentage={feedbackData?.feedbackCountPctChange} />
            </div>
            <div className="w-full">
                <MetricsCard title="CSAT Score" value={`${(Number(feedbackData?.csatScoreCurr)*100).toFixed(1)} %`} percentage={feedbackData?.csatScoreChange} />
            </div>
            <div className="h-auto p-4 pt-0">
                <Separator  orientation="vertical" />
            </div>
            <FeedbackCard
              icon={'ph:smiley-light'}
              bgColor={'bg-[#E9F3E8]'}
              iconColor={'#008710'}
              title={'Positive'}
              value={feedbackData?.totalPositiveFeedbackCount}
              />
                          <FeedbackCard
              icon={'ph:smiley-meh-light'}
              bgColor={'bg-[#DC68031A]'}
              iconColor={'#DC6803'}
              title={'Neutral'}
              value={feedbackData?.totalNeutralFeedbackCount}
              />
                          <FeedbackCard
              icon={'ph:smiley-sad-light'}
              bgColor={'bg-[#FFECE9]'}
              iconColor={'#E9231D'}
              title={'Negative'}
              value={feedbackData?.totalNegativeFeedbackCount}
              />
            </div>

        </Card>
    )
}


const FeedbackCard = ({icon, bgColor, iconColor, title, value}) => {
    return (
        <div className={`p-4 ${bgColor} flex mx-5 mb-5 rounded-xl`}>
            <div className="">
                <Icon icon={icon}  color={iconColor} fontSize={40}/>
            </div>
            <div className="flex flex-col justify-between ml-6">
                <div className="text-xs text-[#5B6169]">
                    {title}
                </div>
                <div className="text-2xl font-medium">
                    {value}
                </div>
            </div>
        </div>
    )
}


export default CustomerFeedbackComponent