import React, { createContext, useState, useContext } from 'react';
import {
  ToastProvider,
  Toast,
  ToastViewport,
  ToastTitle,
  ToastDescription,
} from '@radix-ui/react-toast';

type NotificationType = 'success' | 'error' | 'info';

interface Notification {
  type: NotificationType;
  title: string;
  description: string;
}

interface NotificationContextProps {
  notify: (type: NotificationType, title: string, description: string) => void;
}

const NotificationContext = createContext<NotificationContextProps | null>(null);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notify = (type: NotificationType, title: string, description: string) => {
    console.log('inside notifcatio  -=----> ');
    

    setNotifications((prev) => [...prev, { type, title, description }]);
    setTimeout(() => {
      setNotifications((prev) => prev.slice(1)); // Auto-remove notification after a delay
    }, 3000);
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      <ToastProvider swipeDirection="up">
        {notifications.map((notification, index) => (
          <Toast key={index} className={`border ${getToastColor(notification.type)} bg-white shadow-lg p-3 rounded-xl`}>
            <ToastTitle className="font-medium">{notification.title}</ToastTitle>
            <ToastDescription className="text-sm">{notification.description}</ToastDescription>
          </Toast>
        ))}
        <ToastViewport className="fixed z-[10000] top-4 left-[40%] rounded flex flex-col gap-4 w-80 max-w-full z-50" />
      </ToastProvider>
      {children}
    </NotificationContext.Provider>
  );
};

const getToastColor = (type: NotificationType) => {
  switch (type) {
    case 'success':
      return 'border-green-500 text-green-700';
    case 'error':
      return 'border-red-500 text-red-700';
    case 'info':
      return 'border-blue-500 text-blue-700';
    default:
      return '';
  }
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
