import Icon from "@/components/global/icon/Icon";


const MetricsCard = ({title, value, percentage, unit}: any) => {
    return (
        <div className="flex flex-col p-4 metrics-card mx-5 mb-5">
            <div className="text-[#5B6169] text-xs font-medium mb-2">
                {title}
            </div>
            <div className="text-2xl text-[#161B1F] font-medium mb-2">
                {value}
            </div>
            <div className={`px-2 py-1 rounded-3xl ${percentage >= 0 ? 'bg-[#E9F3E8] text-[#008710]' : 'bg-[#FFECE9] text-[#E9231D]' } bg-[#E9F3E8] text-[#008710] text-xs w-fit flex items-center justify-center`}>
                <div className="mr-1">
                    <Icon icon={percentage >= 0 ? "solar:course-up-bold" : "solar:course-down-bold"} color={percentage >= 0 ? "#008710" : "#E9231D"}/>
                </div>
                {Math.abs(percentage) + '%'}
            </div>

        </div>
    )
}

export default MetricsCard;