// Dummy data for tabs
  
  import { TrendingUp } from "lucide-react"
  import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts"
  
  import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
  } from "@/components/ui/chart"
  // const chartData = [
  //   { month: "January", desktop: 186 },
  //   { month: "February", desktop: 305 },
  //   { month: "March", desktop: 237 },
  //   { month: "April", desktop: 73 },
  //   { month: "May", desktop: 209 },
  //   { month: "June", desktop: 214 },
  // ]
  
  const chartConfig = {
    value: {
      label: "value",
      color: "hsl(var(--chart-1))",
    },
  } satisfies ChartConfig
  
  export function BarChartComponent({chartData, aspect = null}) {



    return (
        <CardContent>
          <ChartContainer config={chartConfig} className={aspect ?  aspect: "aspect-[3]"}>
            <BarChart
              accessibilityLayer
              data={chartData}
              layout="vertical"
              margin={{
                left: -40,
              }}
              height={chartData?.length * 100}
              // barCategoryGap="20%
            >
              <XAxis type="number" dataKey="value" hide />
              <YAxis
                dataKey="type"
                type="category"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value}
                width={350}
                interval={0}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Bar dataKey="value" fill="var(--color-value)" radius={5} >
              <LabelList
                dataKey="value"
                position="right"
                offset={8}
                className="fill-foreground"
                fontSize={12}
              />
              </Bar>
            </BarChart>
          </ChartContainer>
        </CardContent>

    )
  }
  
  export default BarChartComponent;
  