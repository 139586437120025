import { Card } from "@/components/ui/card";
import MetricsCard from "./metricsCard";


const RevenueMetricsComponent = ({revenueData, bthData, redInbound}: any) => {

   return (
        <div className="">
        {revenueData && <Card className="flex flex-col shadow-none">
            <div className="text-xl font-semibold p-5">{'Revenue Metrics'}</div>
            <div className="flex">
                <div className="w-full">
                    <MetricsCard title="Inbound" 
                    value={revenueData?.totalInboundRevenueCurrent} 
                    percentage={revenueData?.totalInboundRevenueChangePct} 
                    />
                </div>
                <div className="w-full">
                    <MetricsCard title="Outbound" 
                    value={revenueData?.totalOutboundRevenueCurrent} 
                    percentage={revenueData?.totalOutboundRevenueChangePct}  
                    />
                </div>
                <div className="w-full">
                    <MetricsCard title="LAMA/DAMA" 
                    value={revenueData?.totaLamaDamaRevenueCurrent} 
                    percentage={revenueData?.totaLamaDamaRevenueChangePct} 
                    />
                </div>
                <div className="w-full">
                    <MetricsCard title="Intra-Branches"
                    value={revenueData?.totalIntraBranchesRevenueCurrent} 
                    percentage={revenueData?.totalIntraBranchesRevenueChangePct} 
                    />
                </div>
            </div>
        </Card>}
        <div className="pt-4 flex w-full space-x-5">
        {/* Bill To Hospital Card */}
        {bthData && <Card className="flex flex-col basis-1/2 shadow-none">
          <div className="text-xl font-semibold p-5">{'Bill To Hospital'}</div>
          <div className="flex">
            <div className="w-full">
              <MetricsCard title="No. Of Cases" value={bthData?.totalTripsCurrent} percentage={bthData?.totalTripsChangePct} />
            </div>
            <div className="w-full">
              <MetricsCard title="Total Revenue" value={bthData?.totalBthRevenueCurr} percentage={bthData?.totalBthRevenueChangePct} />
            </div>
          </div>
        </Card>}

        {/* Free Trips Card */}
        {bthData && <Card className="flex flex-col basis-1/4 shadow-none">
          <div className="text-xl font-semibold p-5">{'Free Cases'}</div>
          <div className="flex">
            <div className="w-full">
              <MetricsCard title="No. Of Cases" value={bthData?.totalFreeTripsCurr} percentage={bthData?.totalFreeTripsChangePct} />
            </div>
          </div>
        </Card>}

        {/* Red Inbound Card */}
        {redInbound && <Card className="flex flex-col basis-1/4 shadow-none">
          <div className="text-xl font-semibold p-5">{'Red Inbound'}</div>
          <div className="flex">
            <div className="w-full">
              <MetricsCard title="Inbound" value={redInbound?.redInboundCountCurr} percentage={redInbound?.redInboundCountChange} />
            </div>
          </div>
        </Card>}
        </div>
        </div>
    )

}

export default RevenueMetricsComponent;