import { Card } from "@/components/ui/card";
import { LineChartComponent } from "../chart-components/lineChart";
import { tripsByPlatformKeyMapping, tripsOtherDataKeyMapping, tripTabs } from "../analyticsDashboard.constants";
import { useEffect, useState } from "react";
import Icon from "@/components/global/icon/Icon";

import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";
import MedicalCausesComponent from "./medicalCauses";
import TripsByPlatform from "./tripsByPlatform";

function TimeRangeSelector({onSelectedValueChange}: any) {
  return (
    <Select defaultValue="day" onValueChange={(value) => onSelectedValueChange(value)}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Select time range" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="day">Day</SelectItem>
        <SelectItem value="weekly">Week</SelectItem>
        <SelectItem value="monthly">Month</SelectItem>
      </SelectContent>
    </Select>
  );
}



const TripsComponent = ({tripsData, medicalCausesData, tripsByPlatform}: any) => {
    const [activeTab, setActiveTab] = useState('totalInbound');

    const [currentXAxisKey, setCurrentXAxisKey] = useState('day');

    const [tripsChartData, setTripsChartData] = useState({});


    const[tripsAggrData, settripsAggrData] = useState(tripTabs);


    useEffect(() => {
        if(tripsData) {
            console.log(tripsData);
            
            settripsAggrData({
                ...tripsAggrData,
                "totalInbound": {
                    ...tripsAggrData.totalInbound,
                    value: tripsData?.totalInbound,
                    percentage: tripsData?.totalInboundChange,
                },
                "totalOutbound": {
                    ...tripsAggrData.totalOutbound,
                    value: tripsData?.totalOutbound,
                    percentage: tripsData?.totalOutboundChange,
                },
                // "totalLamaDama": {
                //     ...tripsAggrData.totalLamaDama,
                //     value: tripsData?.totalLamaDama,
                //     percentage: tripsData?.totalLamaDamaChange,
                // },
                "totalIntraBranch": {
                    ...tripsAggrData.totalIntraBranch,
                    value: tripsData?.totalIntraBranch,
                    percentage: tripsData?.totalIntraBranchChange,
                },
                "totalTrips": {
                    ...tripsAggrData.totalTrips,
                    value: tripsData?.totalTrips,
                    percentage: tripsData?.totalTripsChange,
                }
            })

            setTripsChartData({
                day: {
                    key: "key",
                    data: tripsData?.aggTripsData?.["daily"]?.records,
                },
                weekly: {
                    key: "key",
                    data: tripsData?.aggTripsData?.["weekly"]?.records,
                },
                monthly: {
                    key: "key",
                    data: tripsData?.aggTripsData?.["monthly"]?.records,
                }
            })
        }
    }, [tripsData]);



    return (
        <>
        <Card
        className={`mx-auto mb-5 border-[1px] overflow-hidden text-black shadow-none`}
      >
        {/* <div className="bg-white"> */}
            <div className="text-xl font-semibold p-6">{'Cases'}</div>

            <div className="flex ml-6 mt-0">
            {Object.entries(tripsAggrData).map((entry)=> {                
                return (
                    <div className={`flex flex-col px-6 chart-tab-wrapper py-5 ${activeTab === entry[0] && 'chart-active-tab relative top-px'} cursor-pointer`}
                        onClick={()=> setActiveTab(entry[0])}
                    >
                        <div className={`text-xs mb-2 ${activeTab === entry[0] ? 'text-[#175CD3]' : 'text-[#5B6169]'}`}>
                            {entry[1].text}
                        </div>
                        <div className="text-2xl font-semibold mb-2">
                            {entry[1].value}
                        </div>
                        <div className={`px-2 py-1 rounded-3xl ${entry[1].percentage > 0 ? 'bg-[#E9F3E8] text-[#008710]' : 'bg-[#FFECE9] text-[#E9231D]' } bg-[#E9F3E8] text-[#008710] text-xs w-fit flex items-center justify-center`}>
                            <div className="mr-1">
                                <Icon icon={entry[1].percentage > 0 ? "solar:course-up-bold" : "solar:course-down-bold"} color={ entry[1].percentage > 0 ? "#008710" : "#E9231D"}/>
                            </div>
                            {Math.abs(entry[1].percentage) + '%'}
                        </div>
                    </div>
                )
            })}
        </div>

            <Card className={`m-6 mb-0 mt-0 shadow-none ${activeTab === 'totalInbound' && 'rounded-tl-none'}`}>
                <div className="flex justify-end p-3 px-6">
                   <TimeRangeSelector onSelectedValueChange={(value: any)=> setCurrentXAxisKey(value)}/>
                </div>
                <LineChartComponent 
                dataXaxisKey={tripsChartData[currentXAxisKey]?.key} dataYAxisKey={activeTab} chartData={tripsChartData[currentXAxisKey]?.data}
                />
            </Card>
            <div className="pb-4 pt-0 flex mx-6">
            {medicalCausesData?.[tripsOtherDataKeyMapping[activeTab]] && <MedicalCausesComponent medicalCausesData={medicalCausesData?.[tripsOtherDataKeyMapping[activeTab]]} />}
            <div className="pl-0 w-[30%]">
            {tripsByPlatform?.[tripsByPlatformKeyMapping[activeTab]] && <TripsByPlatform tripsData={tripsByPlatform?.[tripsByPlatformKeyMapping[activeTab]]}/>}
            </div>
        </div>
        {/* </div>  */}
      </Card>
      </>
    )
}

export default TripsComponent;