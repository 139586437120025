"use client"

import { TrendingUp } from "lucide-react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig

export function LineChartComponent({dataXaxisKey, dataYAxisKey, chartData}: any) {

  console.log("sfdffgdfg", dataXaxisKey, dataYAxisKey, chartData);
  

  function formatDate(dateString: string) {
    if (!dateString) return "";
  
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
  
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${parseInt(day)} ${months[parseInt(month) - 1]}'${year.slice(-2)}`;
  
    return formattedDate;
  }




  return (
    <div>
      {/* <CardHeader>
        <CardTitle>Line Chart</CardTitle>
        <CardDescription>January - June 2024</CardDescription>
      </CardHeader> */}
      <CardContent className="p-3">
        <div className="py-4"></div>
        <ChartContainer config={chartConfig}>
          <LineChart
            accessibilityLayer
            data={chartData}
            margin={{
              // left: 50,
              right: 12,
              bottom: 50,
              top: 10
            }}
          >
            <CartesianGrid vertical={false} />
            <YAxis
              type="number"
              tickLine={false}
              axisLine={false}
              // tickMargin={10}
              // tickCount={5} // Optional: control the number of ticks
            />
            <XAxis
              dataKey={dataXaxisKey}
              tickLine={false}
              axisLine={false}
              tickMargin={20}
              tickFormatter={(value) => {
                return formatDate(value)
              }}
              // interval={0}
              angle={-30}
              textAnchor="end"
            />
            <ChartTooltip
            formatter={(value, __,record)=> {
              console.log('value from tooltip===>>', value, record);
              const date = record.payload?.key;
              return (
              <div>
                <div>{
                  // new Date(date).toLocaleDateString("en-US", {
                  //   month: "short",
                  //   day: "numeric",
                  //   year: "numeric",
                  // })
                  }</div>
                  <div>{value}</div>
              </div>
              )
            }}
              // cursor={false}
              content={<ChartTooltipContent 
                className="w-[150px]"
                hideLabel
                nameKey={dataXaxisKey}
                labelFormatter={(value) => {
                  return new Date(value).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })
                }}
                 />}
            />
            <Line
              dataKey={dataYAxisKey}
              type="linear"
              stroke="var(--color-desktop)"
              strokeWidth={2}
              // dot={false}
              dot={{
                fill: "var(--color-desktop)",
              }}
              activeDot={{
                r: 6,
              }}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </div>
  )
}
