import useUser from "@/api/hooks/useUser";
import Icon from "@/components/global/icon/Icon";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {Modal} from "antd";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useRef, useState } from "react";
import PickupAddressInput from "./pickupAddress";
import "./caseCreation.css";

// import Modal from "react-modal";
import { createEmergencyCase } from "@/hooks/api/apiConfig";
import AssignAmbulanceModal from "./vehicle-assignment/vehicleAssignment";
import MedicalSymptoms from "./medicalSymptoms";
import Loader from "@/components/loader";
import useOrders from "@/api/hooks/useOrder";
import { useNotification } from "@/components/ui/notificationProvider";
import { caseCreationAccessSources } from "@/globalConstants";

// Modal.setAppElement("#root");
const CaseCreation = () => {


    const [isOpen, setIsOpen] = useState(false);
    let orderData = useRef<any>({});
    const [isAmbulanceAssignmentModalOpen, setIsAmbulanceAssignmentModalOpen] = useState(false); // Control modal state
    const [apiLoading, setApiLoading] = useState(false);

    const {addNewCaseToUnassigned} = useOrders();

    const {notify} = useNotification();




    // const [caseCreationModalOpen, setCaseCreationModalOpen] = useState(false);
    const { user, selectedClientId, partnerListMapping, ...rest } = useUser();
    const sources = user?.dedicatedToClientIds || [];
    const [medicalSymptoms, setMedicalSymptoms] = useState<any>([]);

    const modalRef: any = useRef();

    const ClientDataForSource = partnerListMapping.filter((entry)=> {
        return entry.clientId === selectedClientId
    })?.[0];

    const [formData, setFormData] = useState({
        branchName: sources.filter((entry)=> entry.value === selectedClientId)[0]?.label || "",
        pickupAddress: {},
        mobileNumber: "",
        contactPersonName: "",
        medicalCondition: "",
        age: "",
      });
    
      const toggleModal = () => {
        setIsOpen((prev) => !prev);
        setFormData({
            branchName: sources.filter((entry)=> entry.value === selectedClientId)[0]?.label || "",
            pickupAddress: {},
            mobileNumber: "",
            contactPersonName: "",
            medicalCondition: "",
            age: ""
        })
    };
      const isFormValid =
        formData.branchName.trim() &&
        formData.pickupAddress.hasOwnProperty('address') &&
        formData.mobileNumber.trim()?.length === 10;
    
      const handleInputChange = (e: any) => {
        const { id, value } = e.target;
        setFormData(  (prev) => { return { ...prev, [id]: value }});
      };
    
      const handleBranchChange = (value: any) => {
        setFormData({ ...formData, branchName: value });
      };
    
      const handleFormSubmit = (e: any) => {
        e.preventDefault();
        if (isFormValid) {
            handleCreateCase();
        //   alert(JSON.stringify(formData, null, 2));
          // Add case creation logic here
        }
      };


      const closeAssignAmbModal = (e: any) => {
        setIsAmbulanceAssignmentModalOpen(false);
      }


      const handleCreateCase = async () => {

        const vv = medicalSymptoms;

        const requestBody = {
            "mobile": formData.mobileNumber,
            "isEmergencyCase": true,
            // "medicalCondition": formData.medicalCondition,
            // "requestedFor" : {
            //     "medicalIssue": {
            //         "reason": [formData.medicalCondition]
            //     }  
            // },

            "requestedFor": {
                "medicalIssue": {
                 "reason": medicalSymptoms?.reason || [],
                },
                 "age": Number(formData.age),
                 "name": formData.contactPersonName,
             },
            "requestedBy": {
                "mobile": formData.mobileNumber,
                "name": formData.contactPersonName
            },
            "originSource": {
                "source": selectedClientId,
                "platform": "SALUS_TEST"
            },
            "attributedTo": "SALUS",
            "serviceType": "STANDARD",
            "city": ClientDataForSource?.city,
            "assignmentType": "MANUAL",
            "orderClassification": "INBOUND",
            "generatedBy": user.email,
            "waypoints": [
                formData.pickupAddress,
                {
                    "geo": {
                        "lat": Number(ClientDataForSource?.lat),
                        "long": Number(ClientDataForSource?.lng)
                    },
                    "address": {
                        "location": ClientDataForSource?.address,
                        "city": ClientDataForSource?.city,
                        "state": ClientDataForSource?.state,
                        "pincode": ClientDataForSource?.pincode
                    },
                    "type": "dropoff",
                    "sourceId": selectedClientId,
                    "sequence": 1
                }
            ]
        }

        try {
            setApiLoading(true);
            const response = await createEmergencyCase(selectedClientId, requestBody);
            setIsOpen(false);
            orderData.current = response?.data;
            addNewCaseToUnassigned(response?.data);
            setIsAmbulanceAssignmentModalOpen(true);
        }
        catch (err) {
            console.log(err);
            
            notify('error','', JSON.stringify(err?.response?.data?.message ?? err?.message));
        }
        finally {
            setApiLoading(false);
        }
        
      }


    return (
        <>
       <Button onClick={()=>setIsOpen(true)}>
            <div className="flex items-center justify-center">
            <Icon icon="tabler:plus" color="white" fontSize={24} />
            <span className="pl-2">{'Create Case'}</span>
            </div>
        </Button>

        {/* <AssignAmbulanceModal /> */}



        <Modal
        open={isOpen}
        onClose={toggleModal}
        onCancel={toggleModal}
        footer={null}
        width={'450px'}
        // title="Case Creation Modal"
        // className="relative mx-auto my-auto bg-white w-[400px] p-4 rounded shadow-lg"
        // overlayClassName="fixed top-0 z-[100] left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div className="pb-6">
        <div className="pb-2 text-lg font-medium">{'New Emergency Case'}</div>
        <Separator/>
        </div>

        <form className="space-y-4" onSubmit={handleFormSubmit}>
          <div>
            <Label htmlFor="branch-name" className="mb-2 block">
              Branch name <span className="text-red-500">*</span>
            </Label>
            <Input
              id="branch-name"
              className="input-box"
              value={formData.branchName}
              onChange={handleInputChange}
              placeholder="Branch Name"
              required
              disabled
            />
          </div>

          <PickupAddressInput formData={formData}  setFormData={handleInputChange} modalRef={modalRef}/>
          <div>
            <Label htmlFor="mobileNumber" className="mb-2 block">
              Attender mobile number <span className="text-red-500">*</span>
            </Label>
            <Input
                className="input-box"
              id="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              type="tel"
              placeholder="Mobile Number"
              required
            />
          </div>

          <div>
            <Label htmlFor="contactPersonName" className="mb-2 block">
              Patient name
            </Label>
            <Input
            className="input-box"
              id="contactPersonName"
              value={formData?.contactPersonName || ""}
              onChange={handleInputChange}
              placeholder="Enter patient name"
            />
          </div>
          <div>
            <Label htmlFor="age" className="mb-2 block">
              Age
            </Label>
            <Input
            className="input-box"
              id="age"
              value={formData?.age || ""}
              onChange={handleInputChange}
              placeholder="Enter patient age"
            />
          </div>

          <div>
            <Label htmlFor="medicalCondition" className="mb-2 block">
              Medical condition
            </Label>
            {/* <Input
              id="medicalCondition"
              value={formData?.medicalCondition || ""}
              onChange={handleInputChange}
              placeholder="Enter medical condition"
            /> */}
            <MedicalSymptoms 
            onDone={(value)=> console.log(value)}
            value={medicalSymptoms}
            setValue={setMedicalSymptoms}
            />
          </div>

          <div className="pt-4">
            <Button type="submit" className="w-full" disabled={!isFormValid}>
              {apiLoading ? <Loader /> : <span>Create Case</span>}
            </Button>
          </div>
        </form>
      </Modal>
      { orderData.current && isAmbulanceAssignmentModalOpen && <AssignAmbulanceModal isOpen={isAmbulanceAssignmentModalOpen} setIsOpen={closeAssignAmbModal} orderData={orderData.current}/>}
        </>
    )

}

export default CaseCreation;