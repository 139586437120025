import React, { memo, useEffect, useState } from "react";
import {Modal} from "antd";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { searchRadiusConfig } from "../caseCreation.constants";
import { getNearbyVehicles, triggerManualDispatch } from "@/hooks/api/apiConfig";
import CancelCaseModal from "./cancelCaseModal";
import Loader from "@/components/loader";
import { Separator } from "@/components/ui/separator";
import useUser from "@/api/hooks/useUser";
import { useNotification } from "@/components/ui/notificationProvider";
import useOrders from "@/api/hooks/useOrder";


const NocasesFound = () => {
    return (
        <div className="flex flex-col items-center justify-center text-center px-6 py-8 bg-white">
          {/* Ambulance Icon with Cross */}
          <div className="flex items-center justify-center">
            <img src="/images/no-amb-found.svg" />
          </div>
          {/* Text Content */}
          <h2 className="text-lg font-semibold text-black mb-2">
            No Ambulance Available
          </h2>
          <p className="text-sm text-gray-600 mb-2">
            All ambulances are busy at the moment. Please reach out to RED
            Relationship Manager at your hospital or Call{" "}
            <a
              href="tel:180012191911"
              className="text-blue-500 font-medium underline"
            >
              180012191911
            </a>
          </p>
          <p className="text-sm text-gray-500">
            Please change search radius or try after some time.
          </p>
        </div>
      );
}



function _AssignAmbulanceModal({orderData, isOpen, setIsOpen}) {
  const [selectedVehicleData, setSelectedVehicleData] = useState<any>({});
  const [isCancelCaseModalVisible, setIsCancelCaseModalVisible] = useState(false);

  const {notify} = useNotification();

  const [vehicleApiLoading, setvehicleApiLoading] = useState(false);
  const [assignmentApiLoading, setAssignmentApiLoading] = useState(false);

  const [availableVehicles, setAvailablevehicles] = useState<any>([]);
  const [searchRadius, setSearchRadius] = useState('10000');
  const [modalOpen ] = useState(isOpen);
  const {selectedClientId, partnerListMapping} = useUser(); 
  const {removeCaseFromUassigned} = useOrders();
   

   const ClientDataForSource = partnerListMapping.filter((entry)=> {
        return entry.clientId === selectedClientId
    })?.[0];


  console.log(orderData, '--------------........>??');
  

  useEffect(()=> {
    if(orderData) {
        fetchAvailableVehicles();
    }
    return () => {
        setAvailablevehicles([]);
        setSelectedVehicleData({});
    }
  }, [searchRadius, orderData?.caseId]);


  const triggerDisptach = async (e: any) => {
    const requestBody = {
        "mobile": orderData?.patientAttendantDetails?.mobile,
        "originSource": orderData?.caseSource,
        "city": ClientDataForSource?.city,
        "landmark": orderData?.waypoints?.[0],
        "dropOff": orderData?.waypoints?.[1],
        "entityRequired": [
          {
            "id": selectedVehicleData?.pilotId,
            "type": "PILOT",
            "ambNumber": selectedVehicleData?.ambulanceNumberPlate,
            "partnerLevel": "ONE_P",
            "subtype": selectedVehicleData?.ambulanceSpec
          }
        ]
      }

      try {
        setAssignmentApiLoading(true);
        const response = await triggerManualDispatch(orderData?.caseId, orderData?.caseSource?.source, requestBody);
        setAssignmentApiLoading(false);
        removeCaseFromUassigned({orderId: orderData?.caseId});
        notify("success", "", "Dispatch triggered, Assignment in progress");
        setIsOpen(e);
      }
      catch (err) {
        notify('error',"", "Error assigning ambulance");
        setAssignmentApiLoading(false);
      }


  }


  const fetchAvailableVehicles = async () => {
    const params = `searchRadius=${searchRadius}`
    try {
        setvehicleApiLoading(true);
        const vehiclesData = await getNearbyVehicles(orderData?.caseSource?.source || 'DEMO00009' , orderData?.caseId || 'RED_VBGQX2HN', params);
        // console.log(vehiclesData?.data);
        setAvailablevehicles(vehiclesData?.data?.['ONE_P']?.length ? vehiclesData?.data?.['ONE_P'] : []);
    }
    catch (err) {
        setAvailablevehicles([]);
    }
    finally {
        setvehicleApiLoading(false);
    }
  }



  const vehicles = [
    { id: "TS09UD8911", name: "TS 09 UD 8911", time: "4 mins", distance: "2.8 km", type: "ALS 5G", tag: "RED Vehicle" },
    { id: "TS05JU9203", name: "TS 05 JU 9203", time: "5 mins", distance: "4.1 km", type: "ALS 5G", tag: "RED Vehicle" },
    { id: "TS01JU7289-A", name: "TS 01 JU 7289", time: "6 mins", distance: "4.8 km", type: "ALS", tag: "Hospital Vehicle" },
    { id: "TS01JU7289-B", name: "TS 01 JU 7289", time: "6 mins", distance: "4.8 km", type: "BLS", tag: "Hospital Vehicle" },
  ];

  const handleSearchRadiusChange = (value: string) => {
    setSearchRadius(value)
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={setIsOpen}
    //   className="relative mx-auto my-auto bg-white w-[400px] p-4 rounded shadow-lg"
    //   overlayClassName="fixed top-0 z-[100] left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="space-y-4">
        {/* Modal Header */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Assign Ambulance</h2>
        </div>

        {/* Patient Details */}
        <div className="space-y-2 border-b pb-4">
          <div className="bg-[#F7F7F7] p-4 rounded-lg">
            <p className="text-gray-700 font-medium">{"+91-" + orderData?.patientAttendantDetails?.mobile}</p>
            <p className="text-gray-500 text-sm">Red Health</p>
            <p className="flex items-center text-sm text-gray-600">
                <span className="mr-1">📍</span>{orderData?.waypoints?.[0]?.address?.location}
            </p>
            {orderData?.patientDetails?.medicalIssue?.reason && <><div className="py-2">
                <Separator />
            </div>
            <p className="text-sm font-semibold text-[#161B1F]">{"Medical conditions: "} <span className="font-normal">{orderData?.patientDetails?.medicalIssue?.reason?.toString()}</span></p> </>}
          </div>
          <p className="text-sm text-[#6D747B] mt-2 italic font-medium ml-1">This is Bill to Hospital Case</p>
        </div>

        {/* Select Radius */}
        <div className="flex justify-between items-center">
          <Label htmlFor="radius" className="font-medium text-sm">
            Select Vehicle
          </Label>
          <Select
                defaultValue={searchRadius}
                value={searchRadius}
                onValueChange={handleSearchRadiusChange}
            >
                <SelectTrigger className='w-full max-w-[100px] sm:w-[180px] sm:max-w-full'>
                <SelectValue placeholder='Select a fruit' />
                </SelectTrigger>
                <SelectContent className="z-[20000]">
                <SelectGroup>
                    {searchRadiusConfig?.map((itm: any) => (
                    <SelectItem key={`${itm?.label}-${itm?.value}`} value={itm?.value}>
                        {itm?.label}
                    </SelectItem>
                    ))}
                </SelectGroup>
                </SelectContent>
            </Select>
        </div>

        {/* Vehicle List */}
        {availableVehicles?.length && !vehicleApiLoading ?  <ScrollArea className="max-h-60">
          <div className="space-y-2">
            {availableVehicles.map((vehicle: any) => (
              <label
                key={vehicle.ambulanceId}
                className={`flex items-center justify-between border px-4 py-2 rounded-lg ${
                  selectedVehicleData?.ambulanceNumberPlate === vehicle.ambulanceNumberPlate ? "bg-indigo-50 border-indigo-500" : "bg-white border-gray-300"
                }`}
              >
                <div>
                  <input
                    type="radio"
                    name="vehicle"
                    className="mr-3"
                    value={vehicle.id}
                    onChange={() => setSelectedVehicleData(vehicle)}
                    checked={selectedVehicleData?.ambulanceNumberPlate === vehicle.ambulanceNumberPlate}
                  />
                  <span className="font-medium">{vehicle.ambulanceNumberPlate}</span>
                  <p className="text-sm text-gray-500 pl-6">
                    {`${Math.ceil(Number(vehicle?.pickupEta)/60)} mins`} • {`${(Number(vehicle?.pickupDistance)/1000).toFixed(2)} km`}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-sm font-medium pr-1 pb-1">{vehicle.ambulanceSpec}</p>
                  <p
                    className={`text-xs font-semibold px-2 py-1 bg-[#FFECE9] rounded-2xl ${
                      "RED Vehicle" ? "text-[#E9231D]" : "text-orange-500"
                    }`}
                  >
                    {"RED Vehicle"}
                    {vehicle.tag}
                  </p>
                </div>
              </label>
            ))}
          </div>
        </ScrollArea> : vehicleApiLoading ? <Loader className="h-[200px] max-h-[200px]"/> :  (<NocasesFound />)}

        {/* Footer Buttons */}
        <div className="flex justify-between items-center pt-4 border-t">
          <Button variant="secondary" 
          onClick={()=> setIsCancelCaseModalVisible(true)}
          >
            Cancel Case
          </Button>
          <Button 
            disabled={!selectedVehicleData?.ambulanceNumberPlate}
            onClick={triggerDisptach}
          >
            {assignmentApiLoading ? <div className="w-16 flex"> <Loader /> </div> : 'Assign'}
          </Button>
        </div>
      </div>
      <CancelCaseModal isModalVisible={isCancelCaseModalVisible} setIsModalVisible={setIsCancelCaseModalVisible} orderId={orderData?.caseId} clientId={orderData?.caseSource?.source} closeAssignmentModal={setIsOpen} />
    </Modal>
  );
}

const AssignAmbulanceModal = _AssignAmbulanceModal;
export default AssignAmbulanceModal;
