import React, { useRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { reportTypeMapping } from "../analyticsDashboard.constants";
import { fetchDownReportSignedUrl } from "@/hooks/api/apiConfig";
import useAnalytics from "@/api/hooks/useAnalytics";
import useUser from "@/api/hooks/useUser";
import { useNotification } from "@/components/ui/notificationProvider";
import Icon from "@/components/global/icon/Icon";
import Loader from "@/components/loader";

export default function ReportWithModal({calls, trips, feedbacks, b2h}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const emailInput = useRef("");

  const {notify} = useNotification();

  const { analyticsDateRange } = useAnalytics();
  const {selectedClientId, partnerListMapping} = useUser();

  console.log(partnerListMapping);
  

   const ClientDataForSource = partnerListMapping.filter((entry)=> {
        return entry.clientId === selectedClientId
    })?.[0];


  const [selectedRadio, setSelectedRadio] = useState('Excel');

  const onMenuItemClick = (reportType: any) => {
    setSelectedReport(reportType);
    setIsModalOpen(true);
  };

  const handleDownload = (action: string) => {
    console.log(`Download Excel for: ${selectedReport}`);
    getReportSignedUrlByAction(action)
  };

  const handleSendEmail = (email) => {
    // setIsModalOpen(false);
  };

   const handleRadioChange = (value: string) => {
    setSelectedRadio(value)
   }


  const getReportSignedUrlByAction = async (action: string, email = []) => {

    const requestBody = {
        startTime: analyticsDateRange.from,
        endTime: analyticsDateRange.to,
        reportType: selectedReport,
        action: action,
        emailIds: [...emailInput.current.split(',')],
    }
    try {
        setApiLoading(true);
        const responseData = await fetchDownReportSignedUrl(selectedClientId, requestBody);
        if(action === 'download') {
            window.open(responseData?.data?.reportUrl);
            notify("success", "Success", 'file downloaded successfully.')
        }
        else {
            notify("success", "Success", 'email sent successfully.')
        }
        
        setApiLoading(false);
        setIsModalOpen(false);
    }
    catch (err) {
        setApiLoading(false);
        notify("error", "Error", 'error in downloading/sending email.');

    }
    finally {
        emailInput.current = "";
        // setIsModalOpen(false);
    }
   }


  return (
    <>
    <div className="mx-4">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="flex items-center text-sm font-normal">
            <div className="pr-2"> <img src="/images/File Download.svg" /></div>
            Download Report
            <svg
              className="ml-2 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-48">
          {calls && <DropdownMenuItem onSelect={() => onMenuItemClick("calls")}>
            Calls Report
          </DropdownMenuItem>}
          {trips && <DropdownMenuItem onSelect={() => onMenuItemClick("trips")}>
            Cases Report
          </DropdownMenuItem>}
          {feedbacks && <DropdownMenuItem onSelect={() => onMenuItemClick("feedbacks")}>
            Feedback Report
          </DropdownMenuItem>}
          {b2h && <DropdownMenuItem onSelect={() => onMenuItemClick("b2h")}>
            Bill To Hospital Report
          </DropdownMenuItem>}
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Modal */}
    </div>
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent className="max-w-md">
            <DialogHeader>
              <DialogTitle>{reportTypeMapping[selectedReport]}</DialogTitle>
              <DialogClose />
            </DialogHeader>
            <RadioGroup className="space-y-4" onValueChange={handleRadioChange} value={selectedRadio}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Excel" id="excel" />
               <div className="flex justify-between items-center w-full">
                <Label htmlFor="excel">Excel</Label>
                    <Button
                    variant="default"
                    onClick={()=> handleDownload('download')}
                    className="ml-auto"
                    disabled= {selectedRadio !== 'Excel' || apiLoading}
                    >

                    {apiLoading && selectedRadio === 'Excel' ? <div className="w-16 flex"> <Loader /> </div> : 'Download'}
                    </Button>
                    </div>
              </div>
              <div className="relative my-4 border-t border-gray-200">
                <span className="absolute -top-3.5 left-1/2 bg-white px-2 text-sm text-gray-500">
                  or
                </span>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="Email" id="email" />
                  <Label htmlFor="email">Email</Label>
                </div>
                <div className="mt-2 flex items-baseline justify-between">
                  <div className="pr-5">
                    <Input
                        placeholder="Enter email address"
                        onChange={(e) => emailInput.current = e.target.value }
                        disabled= {selectedRadio !== 'Email'}
                    />
                    <p className="text-xs text-gray-500 mt-1">
                        Add multiple email addresses, separated by commas (,)
                    </p>
                  </div>
                  <Button
                    variant="default"
                    onClick={() => handleDownload('email')}
                    className="mt-2"
                    disabled= {selectedRadio !== 'Email' || apiLoading}
                  >
                   {apiLoading && selectedRadio === 'Email' ? <div className="w-16 flex"> <Loader /> </div> : 'Send'}
                  </Button>
                </div>
              </div>
            </RadioGroup>
          </DialogContent>
        </Dialog>
        </>
  );
}
