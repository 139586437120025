// TODO: need to remove 'draft' from ongoingCasesStatus
export const ongoingCasesStatus = ['dispatched', 'pickedup', 'reached']
export const fulfilledCasesStatus = ['dropped', 'payment', 'fulfilled', 'completed']
export const unassignedCasesStatus = ['draft', 'unfulfilled', 'newcase'];

export const orderStatusMapper = {
    dispatched: {
        label: 'Dispatched',
        className: 'bg-blue-600 text-white',
    },

    cancelled: {
        label: 'Cancelled',
        className: 'bg-gray-600 text-white',
    },
    picked: {
        label: 'Picked',
        className: 'bg-red-600 text-white',
    },
    reached: {
        label: 'Reached',
        className: 'bg-green-100 text-green-600',
    },
    draft: {
        label: 'Case Created',
        className: 'bg-cyan-600 text-white',
    },
    unfulfilled: {
        label: 'Case Created',
        className: 'bg-cyan-600 text-white',
    },
    scheduled: {
        label: 'Case Created',
        className: 'bg-cyan-600 text-white',
    },
    dropped: {
        label: 'Completed',
        className: 'bg-green-600 text-white',
    },
    payment: {
        label: 'Completed',
        className: 'bg-green-600 text-white',
    },
    fulfilled: {
        label: 'Completed',
        className: 'bg-green-600 text-white',
    },
    completed: {
        label: 'Completed',
        className: 'bg-green-600 text-white',
    },
    newcase: {
        label: 'Case Created',
        className: 'bg-cyan-600 text-white',
    },
    pickedup: {
        label: 'Patient picked',
        className: 'bg-red-600 text-white',
    },
}
