import { Card } from "@/components/ui/card";
import MetricsCard from "./metricsCard";


const TimeMetricsComponent = ({timeMetricsData}) => {

    function secondsToReadableTime(totalSeconds: number): string {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Number(Number(totalSeconds % 60).toFixed(1));
      
        const readableTime = [];
        if (hours > 0) readableTime.push(`${hours} hr${hours > 1 ? 's' : ''}`);
        if (minutes > 0) readableTime.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
        if (seconds > 0 || readableTime.length === 0) {
          readableTime.push(`${seconds} sec${seconds > 1 ? 's' : ''}`);
        }
      
        return readableTime.join(' ');
      }



    return (
        <div className="min-w-56 h-full">
        <Card className="flex flex-col shadow-none h-full">
            <div className="text-xl font-semibold p-5">{'Time Metrics'}</div>
            <div className="flex flex-col justify-evenly h-full">
                <MetricsCard title="Avg. Time to Answer" value={secondsToReadableTime(timeMetricsData?.avgTimeToAnswer)} percentage={timeMetricsData?.avgTimeToAnswerChange} />
                <MetricsCard title="Avg. Handling Time" value={secondsToReadableTime(timeMetricsData?.avgHandlingTime)} percentage={timeMetricsData?.avgHandlingTimeChange} />
                <MetricsCard title="Avg. Handling Time - Ambulance Calls" value={secondsToReadableTime(timeMetricsData?.avgHandlingTimeAmbulance)} percentage={timeMetricsData?.avgHandlingTimeAmbulanceChange} />
            </div>
        </Card>
        </div>
    )
}

export default TimeMetricsComponent;