import { debounce, get, isEmpty, keys, map } from "lodash";
import { useEffect, useState } from "react";
import { Select } from "antd";
import "./caseCreation.css";

import { RED_SYMPTOMS } from "./caseCreation.constants";

type Props = {
    onDone: (n: string, v: string) => void
    [key: string]: any
}


const Search = ({ onDone, value, setValue, setError, ...props }: Props) => {
    const [val, setVal] = useState<any>(value || []);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<any>([]);

    useEffect(() => {
        setVal(value);
    }, [value])

    const callAPI = debounce((text: string) => {
        setLoading(true);
        const RED_RESULTS = RED_SYMPTOMS
            .map((item: string) => ({ id: item, title: item }))
            .filter((data: any) => data.title.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
            .slice(0, 5);
        setSearchData([...RED_RESULTS])
    }, 100);

    return <Select
        {...props}
        className="w-full input-box"
        open={open}
        autoClearSearchValue={true}
        mode="multiple"
        showArrow={false}
        filterOption={false}
        showSearch={true}
        placeholder={'Enter medical condition'}
        // notFoundContent={loading ? <Loader size={24} /> : null}
        loading={loading}
        onSearch={callAPI}
        onBlur={()=>setOpen(false)}
        onFocus={() => setOpen(true)}
        value={get(val, "reason", [null]).filter(Boolean).map((item: string, index: number) => ({ label: item, value: get(val, `meta.icd11Id.${index}`, null) }))}
        labelInValue={true}
        onInputKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "Tab") {
                e.preventDefault();
                // if (isEmpty(val))
                    // setError("is required.")
                // else {
                    if (!open)
                        onDone(props.name, val);
                // }
            } else {
                setOpen(true);
            }
        }}
        size="large"
        onChange={(val: any) => {
            // setError(null);
            const res = {
                reason: map(val, (v: any) => v.label),
                meta: {
                    icd11Id: map(val, (v: any) => v.value),
                }
            }
            setValue(res);
            setVal(res);
            setOpen(false);
        }}
    >
        {searchData.map((item: any) => <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>)}
    </Select>
};

export default Search;