import React from "react";
import { GoogleMap, LoadScript, HeatmapLayer, HeatmapLayerF, useLoadScript } from "@react-google-maps/api";
import { Card } from "@/components/ui/card";

// Google Maps configuration
const mapContainerStyle = {
  width: "100%",
  height: "500px",
};
const zoom = 12;

export const mapStyles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ]

export default function GeoHeatmap({geoData}: any) {
  const options = {
    radius:40, // Adjust heatmap radius
    opacity: 2, // Adjust heatmap opacity
  };

  const { isLoaded } = useLoadScript({
    id: "gao-heatmap",
    libraries: ["visualization"],
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API,
  });



  if (!isLoaded) return <div>Loading...</div>;

  return (
      geoData?.length && (
        <Card
        className={`mx-auto mt-2 border-[1px] overflow-hidden text-black shadow-none`}
        >
            <div className="text-xl font-semibold p-6">{'Geo Map (Inbound Cases)'}</div>
            <div className="px-4 pb-5 rounded-xl">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={geoData[0]}
                zoom={zoom}
                options={{styles: mapStyles, mapTypeControl: false}}
            >
                <HeatmapLayer data={geoData.map((entry: any)=> new google.maps.LatLng(entry.lat, entry.lng))} options={options} />
            </GoogleMap>
      </div>
      </Card>
      )
  );
}
