// src/redux/usersSlice.js
import { verifyAuthToken } from '@/api/thunks/user.thunk'
import { userInitialState } from '@/store/redux/user/userStore.constant'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const usersSlice = createSlice({
  name: 'users',
  initialState: userInitialState,
  reducers: {
    updateClientList: (state, action: PayloadAction<any>) => {
      state.clientList = action.payload.clientList
      state.selectedClientId = action.payload.selectedClientId
      if(action.payload.partnerListMapping) {
        state.partnerListMapping = action.payload.partnerListMapping
      }
      if(action.payload.clientMap) {
        state.clientMap = action.payload.clientMap
      }
    },
    updateSelectedClientId: (state, action: PayloadAction<string>) => {
      state.selectedClientId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyAuthToken.pending, (state) => {
        state.status.verifyOtpToken = 'loading'
        state.status.fetchUserInformation = 'loading'
        state.errors.verifyOtpToken = null
      })
      .addCase(
        verifyAuthToken.fulfilled,
        (state, action: PayloadAction<any>) => {
          // Replace `any[]` with the appropriate type
          state.status.verifyOtpToken = 'succeeded'
          state.status.fetchUserInformation = 'succeeded'
          state.user = action.payload
        }
      )
      .addCase(verifyAuthToken.rejected, (state, action) => {
        state.status.verifyOtpToken = 'failed'
        state.status.fetchUserInformation = 'failed'
        state.status.verifyOtpToken =
          action.error.message || 'Failed to fetch orders'
      })
  },
})

export const { updateClientList,updateSelectedClientId } = usersSlice.actions
export default usersSlice.reducer
