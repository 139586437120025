import { Card } from "@/components/ui/card";
import { PieChartComponent } from "../chart-components/pieChart";
import { useEffect, useState } from "react";

const tripsByPlatformData = [
    {
      label: "Ground Operation Cases",
      value: 0,
      percentage: "80.90",
      fill: "hsl(var(--chart-1))",
    },
    {
      label: "CC Cases",
      value: 0,
      percentage: "19.10",
      fill: "hsl(var(--chart-2))",
    },
  ];

const TripsByPlatform = ({tripsData}: any) => {

    const [tripsByPlatform, setTripsByPlatform] = useState(tripsByPlatformData)



      useEffect(() => {
        if(tripsData) {
            setTripsByPlatform([
                {
                    ...tripsByPlatform[0],
                    value: tripsData?.totalGroundOperationTrips,
                    percentage: Number(tripsData?.groundOperationTripsPct).toFixed(1),
                },
                {
                    ...tripsByPlatform[1],
                    value: tripsData?.totalCCTrips,
                    percentage: Number(tripsData?.ccTripsPct).toFixed(1)
                }
            ])
        }
      }, [tripsData])


    return (
        <Card className="flex flex-col h-full w-full shadow-none rounded-l-none">
            <div className="text-xl font-semibold p-5">{'Cases By Platform'}</div>
            <div className="w-full h-full">
                {tripsData && <PieChartComponent data={tripsByPlatform}/>}
            </div>
        </Card>
    )
}

export default TripsByPlatform;