'use client'

import * as React from 'react'
import { CalendarIcon } from '@radix-ui/react-icons'
import { subMonths, format, addMonths, subDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'
import { DateRange } from 'react-day-picker'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import useOrders from '@/api/hooks/useOrder'
import { Separator } from '@/components/ui/separator'
import useAnalytics from '@/api/hooks/useAnalytics'

interface IDatePickerWithRangeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  // handleSelect: (date: DateRange) => void
}

export function DatePickerWithRange({
  className,
//   handleSelect,
}: IDatePickerWithRangeProps) {
  const today = new Date()
  const {
    analyticsDateRange : date,
    updateAnalyticsDateRange
  } = useAnalytics();

  const [currentDate, setCurrentDate] = React.useState<any>(date);

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)

  // Handle date change and range limit checks
  const handleDateChange = (_date: any) => {
    if (!_date) return
    // const isStartDateChanged = _date.from !== date?.from
    // const isEndDateChanged = _date.to !== date?.to
    let __date = _date
    // if (isStartDateChanged && !isEndDateChanged) {
    //   __date = {
    //     from: _date.from,
    //     to: _date.to,
    //   }
    // } else if (isEndDateChanged && !isStartDateChanged) {
    //   __date = {
    //     from: _date.from,
    //     to: _date.to,
    //   }
    // }
    __date = {
        from: _date.from,
        to: _date.to,
      }
    setCurrentDate(__date);

    // updateStartAndEndDate(__date)
    // handleSelect(__date)
  }

//   const startDateLimit = date?.to ? subMonths(date.to, 3) : undefined

  // Handle predefined quick range selection
  const handleQuickSelect = (range: string) => {
    let newRange: DateRange | undefined


    const updateDateRangeForQuickClick = (newDateRange: any) => {
        setCurrentDate(newDateRange)
        const fromDate = new Date(newDateRange.from);
        fromDate.setHours(0, 0, 0, 0);
        const toDate = new Date(newDateRange.to);
        toDate.setHours(23, 59, 59, 999);
        const payload = {
            from : fromDate.getTime(),
            to: toDate.getTime(),
        }
        updateAnalyticsDateRange(payload);
        setIsPopoverOpen(false);
    }

    switch (range) {
      case 'Last 7 Days':
        newRange = { from: subDays(today, 7), to: today }
        updateDateRangeForQuickClick(newRange)
        break
      case 'Last Week':
        newRange = { from: startOfWeek(today, { weekStartsOn: 1 }), to: endOfWeek(today, { weekStartsOn: 1 }) }
        updateDateRangeForQuickClick(newRange)
        break
      case 'Last Month':
        newRange = { from: startOfMonth(today), to: endOfMonth(today) }
        updateDateRangeForQuickClick(newRange)
        break
      case 'Last 30 Days':
        newRange = { from: subDays(today, 30), to: today }
        updateDateRangeForQuickClick(newRange)
        break
      case 'This Year':
        newRange = { from: startOfYear(today), to: endOfYear(today) }
        updateDateRangeForQuickClick(newRange)
        break
      default:
        break
    }

    if (newRange) {
      handleDateChange(newRange)
    }
  }

  const handleSubmit = () => {
    
    // getCaseHistory()
    const fromDate = new Date(currentDate.from);
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(currentDate.to);
    toDate.setHours(23, 59, 59, 999);
    const payload = {
        from : fromDate.getTime(),
        to: toDate.getTime(),
    }
    updateAnalyticsDateRange(payload);
    setIsPopoverOpen(false)
  }

  const handleCancel = () => {
    setIsPopoverOpen(false)
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {currentDate?.from ? (
              currentDate.to ? (
                <>
                  {format(currentDate.from, 'LLL dd, y')} -{' '}
                  {format(currentDate.to, 'LLL dd, y')}
                </>
              ) : (
                format(currentDate.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-4" align="start">
          {/* Quick Date Selectors */}


          {/* <Separator /> */}
          
          {/* Calendar for custom date range */}
          <div className='flex'>
          <Calendar
            initialFocus
            mode="range"
            selected={currentDate as any}
            onSelect={handleDateChange}
            numberOfMonths={2}
            disabled={{
            //   before: startDateLimit, // Disable dates before 3 months from the selected end date
              after: today, // Disable all future dates from today
            }}
          />
                    <div className="flex flex-col gap-2 mb-4">
            <Button variant="outline" onClick={() => handleQuickSelect('Last 7 Days')}>Last 7 Days</Button>
            <Button variant="outline" onClick={() => handleQuickSelect('Last Week')}>Last Week</Button>
            <Button variant="outline" onClick={() => handleQuickSelect('Last Month')}>Last Month</Button>
            <Button variant="outline" onClick={() => handleQuickSelect('Last 30 Days')}>Last 30 Days</Button>
            <Button variant="outline" onClick={() => handleQuickSelect('This Year')}>This Year</Button>
          </div>
          </div>
          
          <Separator />
          
          {/* Cancel and Apply buttons */}
          <div className="flex justify-end gap-4 p-2">
            {/* <Button variant="outline" onClick={handleCancel}>
              Clear
            </Button> */}
            <Button variant="default" onClick={handleSubmit} className="mr-2">
              Apply
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
