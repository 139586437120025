import React, { useState } from "react";
import { Modal, Input, Button, List } from "antd";
import { bookingCancellationReasons } from "../caseCreation.constants";
import { Checkbox } from "@/components/ui/checkbox";
import "../caseCreation.css"
import { Separator } from "@/components/ui/separator";
import { cancelOrder } from "@/hooks/api/apiConfig";
import { useNotification } from "@/components/ui/notificationProvider";
import useOrders from "@/api/hooks/useOrder";

const CancelCaseModal = ({isModalVisible, setIsModalVisible, orderId, clientId, closeAssignmentModal}) => {
//   const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const {removeCaseFromUassigned} = useOrders();

  const {notify} = useNotification();

  const filteredReasons = bookingCancellationReasons.filter((reason) =>
    reason.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleReason = (reason: string) => {
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const handleCancelCase = async (e: any) => {
    console.log("Selected reasons:", selectedReasons);
    const requestBody = {
        reason: selectedReasons.toString(),
    }
    try {
        const response = await cancelOrder(clientId, orderId, requestBody);
        setIsModalVisible(false);
        setTimeout(()=>{closeAssignmentModal(e)}, 1);
        setSelectedReasons([]);
        removeCaseFromUassigned({orderId: orderId})
        notify("success", "", 'Order cancelled successfully.')
    }
    catch (err) {

    }
  };

  return (
    <div>
      <Modal
        title={<div className="px-5">{"Want to cancel case?"}</div>}
        open={isModalVisible}
        width={'400px'}
        className="cancel-case-modal"
        onCancel={() => setIsModalVisible(false)}
        footer={
            <div className="bg-[#F7F7F7] h-20 rounded-b-lg flex items-center justify-end pr-5">
            <Button
                className="bg-black text-white"
                onClick={handleCancelCase}
                disabled={selectedReasons.length === 0} // Disable button if no reasons are selected
            >
                Cancel Case
            </Button>
          </div>
        }
      >
        <div className="pb-5 pt-1">
        <Separator />
        </div>
        <div className="px-5">
            
        <Input
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          allowClear
          prefix={<span role="img" aria-label="search">🔍</span>}
        />

        <div className="mt-4 font-semibold">Please tell the reason to cancel</div>
        <List
            style={{maxHeight: '350px', overflow:'scroll'}}
          dataSource={filteredReasons}
          renderItem={(reason) => (
            <List.Item>
                <div className="flex justify-between items-center w-full">
                 <div>{reason}</div>
                <Checkbox
                    checked={selectedReasons.includes(reason)}
                    onCheckedChange={() => toggleReason(reason)}
                >
                </Checkbox>
              </div>
            </List.Item>
          )}
        />
        </div>
      </Modal>
    </div>
  );
};

export default CancelCaseModal;
