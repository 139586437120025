import { createBrowserRouter } from 'react-router-dom'
import GeneralError from './pages/_errors/general-error'
import NotFoundError from './pages/_errors/not-found-error'
import MaintenanceError from './pages/_errors/maintenance-error'
import { retry } from '@/utils/retryImport'
import ForbiddenError from '@/pages/_errors/forbidden'

const router = createBrowserRouter([
  {
    path: '/sign-in',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/auth/sign-in'))).default,
    }),
  },
  // Auth routes
  {
    path: '/sign-in-1',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/_auth/sign-in'))).default,
    }),
  },
  {
    path: '/sign-in-2',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/_auth/sign-in-2'))).default,
    }),
  },
  {
    path: '/sign-up',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/_auth/sign-up'))).default,
    }),
  },
  {
    path: '/logout',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/auth/log-out'))).default,
    }),
  },

  {
    path: '/forgot-password',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/_auth/forgot-password'))).default,
    }),
  },
  {
    path: '/otp',
    lazy: async () => ({
      Component: (await retry(() => import('./pages/_auth/otp'))).default,
    }),
  },

  // Main routes
  {
    path: '/',
    lazy: async () => {
      const AppShell = await retry(() => import('./components/app-shell'))
      return { Component: AppShell.default }
    },
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/_dashboard'))).default,
        }),
      },
    ],
  },
  {
    path: 'special/:vehicleNo',
    lazy: async () => {
      const AppShell = await retry(() => import('./components/app-shell'))
      return { Component: AppShell.default }
    },
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/specialPage/specialPage'))).default,
        }),
      }
    ]
  },
  {
    path: '/analytics-dashboard',
    lazy: async () => {
      const AppShell = await retry(() => import('./components/app-shell'))
      return { Component: AppShell.default }
    },
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/analyticsPage/analyticsDashboard'))).default,
        }),
      }
    ]
  },
  {
    path: '/case',
    lazy: async () => {
      const AppShell = await retry(() => import('./components/app-shell'))
      return { Component: AppShell.default }
    },
    errorElement: <GeneralError />,
    children: [
      {
        path: ':orderId',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/vitals-page/caseVitalsPage'))).default,
        }),
      },
      {
        path: 'casedetails/:orderId',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/case_details/caseDetails'))).default,
        }),
      },
      {
        path: 'vitals/:orderId',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/vitals-page/caseVitalsPage'))).default,
        }),
      },
    ],
  },
  {
    path: '/dashboard',
    lazy: async () => {
      const AppShell = await retry(() => import('./components/app-shell'))
      return { Component: AppShell.default }
    },
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/_dashboard'))).default,
        }),
      },
      {
        path: 'case-history',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/case history/CaseHistory'))).default,
        }),
      },
      {
        path: 'case-history/:caseId',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/history-case-detail/HistoryCaseDetail'))).default,
        }),
      },
      {
        path: 'profile',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/profile/Profile'))).default,
        }),
      },
      {
        path: 'debug',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/dashboard/debug'))).default,
        }),
      },
      {
        path: 'tasks',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/_tasks'))).default,
        }),
      },
      {
        path: 'chats',
        lazy: async () => ({
          Component: (await retry(() => import('@/components/coming-soon'))).default,
        }),
      },
      {
        path: 'apps',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/_apps'))).default,
        }),
      },
      {
        path: 'users',
        lazy: async () => ({
          Component: (await retry(() => import('@/components/coming-soon'))).default,
        }),
      },
      {
        path: 'analysis',
        lazy: async () => ({
          Component: (await retry(() => import('@/components/coming-soon'))).default,
        }),
      },
      {
        path: 'extra-components',
        lazy: async () => ({
          Component: (await retry(() => import('@/pages/_extra-components'))).default,
        }),
      },
      {
        path: 'vitals',
        lazy: async () => ({
          Component: (await retry(() => import('./pages/dashboard/vitals-page/components/patientMonitoring/patientMonitoring'))).default,
        }),
      },
      {
        path: 'settings',
        lazy: async () => ({
          Component: (await retry(() => import('./pages/_settings'))).default,
        }),
        errorElement: <GeneralError />,
        children: [
          {
            index: true,
            lazy: async () => ({
              Component: (await retry(() => import('./pages/_settings/profile'))).default,
            }),
          },
          {
            path: 'account',
            lazy: async () => ({
              Component: (await retry(() => import('./pages/_settings/account'))).default,
            }),
          },

          {
            path: 'appearance',
            lazy: async () => ({
              Component: (await retry(() => import('./pages/_settings/appearance'))).default,
            }),
          },
          {
            path: 'notifications',
            lazy: async () => ({
              Component: (await retry(() => import('./pages/_settings/notifications'))).default,
            }),
          },
          {
            path: 'display',
            lazy: async () => ({
              Component: (await retry(() => import('./pages/_settings/display'))).default,
            }),
          },
          {
            path: 'error-example',
            lazy: async () => ({
              Component: (await retry(() => import('./pages/_settings/error-example'))).default,
            }),
            errorElement: <GeneralError className='h-[50svh]' minimal />,
          },
        ],
      },
    ],
  },

  // {
  //   path: 'notes',
  //   lazy: async () => {
  //     const AppShell = await retry(() =>  import('./components/app-shell'))
  //     return { Component: AppShell.default }
  //   },
  //   errorElement: <GeneralError />,
  //   children: [
  //     {
  //       path: ':channel_id',
  //       lazy: async () => ({
  //         Component: (await retry(() =>  import('@/pages/dashboard/notes/index'))).default,
  //       }),
  //     },
  //   ],
  // },

  // Error routes
  { path: '/500', Component: GeneralError },
  { path: '/404', Component: NotFoundError },
  { path: '/503', Component: MaintenanceError },
  { path: '/403', Component: ForbiddenError },

  // Fallback 404 route
  { path: '*', Component: NotFoundError },
])

export default router
