import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { Label,  } from "@/components/ui/label"; // Update the imports based on your project
import { Input } from "@/components/ui/input"; // Update the imports based on your project
import useScript from "./useScript";


const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API
let autoCompleteLstnr: any = null;


function extractAddressDetails(place: any) {
    const addressComponents = place.address_components;
  
    const getComponent = (types: string[]) =>
      addressComponents.find((component: any) =>
        types.some((type) => component.types.includes(type))
      )?.long_name;
  
    const city = getComponent(['locality', 'administrative_area_level_2']);
    const state = getComponent(['administrative_area_level_1']);
    const pincode = getComponent(['postal_code']);
  
    return {
      city: city || '',
      state: state || '',
      pincode: pincode || '',
    };
  }


const PickupAddressInput = ({ formData, setFormData , modalRef}: any) => {
    const url = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`

    const [localInput, setLocalInput] = useState(formData?.pickupAddress?.formatted_address || "");
    
    const inputRef: any = useRef()
    const autoCompleteRef: any = useRef()

    useScript(url);
    const handleFocus = (event: any) => {
        const nonDesktop = window.innerWidth < 1021
        nonDesktop && event.target.select()
      }
    

    useEffect(() => {

        const options: any = {
            fields: ['address_components', 'geometry', 'icon', 'name', 'formatted_address'],
            types: ["establishment"]
          }

        if ((window as any)?.google) {
            if (inputRef?.current) (window as any)?.google.maps.event.clearInstanceListeners(inputRef?.current);

            autoCompleteRef.current = new (window as any).google.maps.places.Autocomplete(
              inputRef?.current,
              options,
            )
            autoCompleteLstnr = autoCompleteRef.current.addListener('place_changed', async function () {
              const place = await autoCompleteRef.current.getPlace()
              const formattedAddress = place.formatted_address
              const lat = place.geometry.location.lat()
              const lng = place.geometry.location.lng()
              const { city, state, pincode } = extractAddressDetails(place);
      
              const responsePayload = {
                "geo": {
                    "lat": lat,
                    "long": lng
                },
                "address": {
                    "location": formattedAddress,
                    "city": city,
                    "state": state,
                    "pincode": pincode
                },
                "type": "pickup",
                "sequence": 0
            }
            //   alert(JSON.stringify(responsePayload))
              setFormData({
                target: {
                    id: 'pickupAddress',
                    value: responsePayload,
              }
              })
            //   setPayload(responsePayload)
            //   onAddressSelect && onAddressSelect(responsePayload)
            })
      
        }
    }, [(window as any)?.google])
  
    return (

        <div>
            <Label htmlFor="pickupAddress" className="mb-2 block">
              Pickup address <span className="text-red-500">*</span>
            </Label>
            <Input
            onFocus={handleFocus}
              id="pickupAddress"
              value={formData?.pickupAddress?.address?.location || ""}
              onChange={(e: any)=> setFormData({
                target: {
                    id: 'pickupAddress',
                    value: {
                        address: {
                           location: e.target.value
                        }
                    },
                }
              })}
              onBlur={()=> setFormData({
                target: {
                    id: 'pickupAddress',
                    value: formData?.pickupAddress,
                }})}
              placeholder="Enter pickup address"
              required
              ref={inputRef}
            />
          </div>
    )
};

export default PickupAddressInput;
