import { useEffect } from 'react'

const loadedScripts: any = {};

const useScript = (url: string) => {
  useEffect(() => {
    if (loadedScripts[url]) return;
    
    const script = document.createElement('script')

    script.src = url
    script.async = true

    document.body.appendChild(script);
    loadedScripts[url] = true;

    return () => {
      document.body.removeChild(script)
        loadedScripts[url] = false;
    }
  }, [url])
}

export default useScript