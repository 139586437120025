import { Icon as Iconify } from '@iconify/react'

interface IconProps {
  icon: string
  color: string
  fontSize?: number;
}

const Icon = ({ icon, color, fontSize }: IconProps) => {
  return <Iconify icon={icon} color={color}  fontSize={fontSize}/>
}

export default Icon
