

export const analyticsDashboardAccessSources = [
    'DEMO00009',
    'HYDH133B001',
    'BLRH002B002',
    'DLHH002B001',
    'LCKH003B001',
];

export const caseCreationAccessSources = [
    'DEMO00009',
    'HYDH133B001',
    'BLRH002B002',
    'DLHH002B001',
    'LCKH003B001'
];