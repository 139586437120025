import { updateSelectedDateRange } from "@/store/redux/analytics/analyticsSlice";
import { useAppDispatch } from "@/store/redux/hooks"
import { RootState } from "@/store/redux/store";
import { useSelector } from "react-redux";



const useAnalytics = () => {
    const dispatch = useAppDispatch();
    const { analyticsDateRange } = useSelector((state: RootState) => state.analyticsStore);

    const updateAnalyticsDateRange = ({from, to}: any) => {
        const startDateEpoch = new Date(from).getTime();
        const endDateEpoch = new Date(to).getTime()
        dispatch(updateSelectedDateRange({
            from: startDateEpoch,
            to: endDateEpoch,
        }))
    }


    return {
        analyticsDateRange,
        updateAnalyticsDateRange
    }
}

export default useAnalytics;