import { Card } from "@/components/ui/card"
import BarChartComponent from "../chart-components/barChart"
import { useEffect, useRef, useState } from "react";


const AnsweredCallComponent = ({answeredCallsData}) => {

    const [answeredComposition, setAnsweredComposition] = useState([]);


    function formatTop10Data(rawData: any) {
        // Convert the input object into an array of {type, value}, removing empty keys
        const filteredData = Object.entries(rawData)
          .filter(([key]) => key.trim() !== "") // Exclude empty keys
          .map(([key, value]) => ({ type: key, value })); // Format as {type, value}
        
        // Sort the data in descending order of the value and take the top 10
        const top10Data = filteredData
          .sort((a: any, b: any) => b.value - a.value)
          .slice(0, 10);
      
          setAnsweredComposition(top10Data)
      }

      useEffect(() => {
        if(answeredCallsData) {
            formatTop10Data(answeredCallsData);
        }
      }, [answeredCallsData])



    return (
        <Card className={`mx-auto border-[1px] overflow-hidden text-black w-full shadow-none`}>
            <div className="text-xl font-semibold p-6">{'Top 10 Disposition of Answered Calls'}</div>
            {answeredComposition.length > 0 && <BarChartComponent chartData={answeredComposition} aspect={'aspect-[2]'}/>}
        </Card>
    )
}

export default AnsweredCallComponent