import { startOfMonth } from 'date-fns'

const today = new Date()

const dateObj = {
  from: new Date(startOfMonth(today).setHours(0, 0, 0, 0)).getTime(),
  to: new Date(today).getTime(),
}


export const analyitcsInitialState = {
    analyticsDateRange: dateObj
}