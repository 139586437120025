import { Card } from "@/components/ui/card";
import { PieChartComponent } from "../chart-components/pieChart";


const PatientDemograph = ({patientData}: any) => {

    const getGenderData = (data: any, sufix?: string) => {
        return Object.keys(data).map((entry: any, index: number)=> {
            // return {
            //     label: entry,
            //     value: `${Number(data[entry]).toFixed(2)}`,
            //     percentage: Number(data[entry]).toFixed(2),
            // }
            return {
                label:`${entry.charAt(0).toUpperCase() + entry.slice(1)} ${sufix ? sufix :  ""} `,
                value: Math.floor(Number(data[entry])),
                percentage: Number(data[entry]).toFixed(2),
                fill: `hsl(var(--chart-${index}))`,
            }
        })
    }

    return (
        <Card
        className={`mx-auto mt-2 border-[1px] overflow-hidden text-black shadow-none`}
        >
            <div className="text-xl font-semibold p-6">{'Patient Demographics - Inbound Cases'}</div>
            <div className="flex w-full  p-4 px-6 flex justify-evenly ">
            <Card className="flex h-96 basis-1/2 pb-10 mr-5 shadow-none">
                <div className="w-full h-full">
                    <div className="p-4 text-sm font-medium">{'Gender Distribution'}</div>
                    <PieChartComponent data={getGenderData(patientData?.gender)}   legendFlexView ={true}/>
                </div>
            </Card>
            <Card className="flex h-96 basis-1/2 pb-10 shadow-none">
                <div className="w-full h-full">
                <div className="p-4 text-sm font-medium">{'Age Distribution'}</div>
                <PieChartComponent data={getGenderData(patientData?.age, 'Yrs')}  legendFlexView ={true}/>
                </div>
            </Card>
            </div>
        </Card>
    )


}

export default PatientDemograph;

