import { Card } from "@/components/ui/card";
import { LineChartComponent } from "../chart-components/lineChart";
import { incomingCallTabs } from "../analyticsDashboard.constants";
import { act, useEffect, useState } from "react";
import Icon from "@/components/global/icon/Icon";



import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";

function TimeRangeSelector({onSelectedValueChange}: any) {
  return (
    <Select defaultValue="day" onValueChange={(value) => onSelectedValueChange(value)}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Select time range" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="day">Day</SelectItem>
        <SelectItem value="weekly">Week</SelectItem>
        <SelectItem value="monthly">Month</SelectItem>
      </SelectContent>
    </Select>
  );
}



const IncomingCallsComponent = ({callsData}: any) => {

    const [activeTab, setActiveTab] = useState('totalCalls');
    const [currentXAxisKey, setCurrentXAxisKey] = useState('day');

    const [callAggregateData, setCallAggregateData] = useState({});


    const[incomingCalls, setIncomingCalls] = useState(incomingCallTabs);

    useEffect(() => {
        if(callsData) {
            setIncomingCalls({
                ...incomingCalls,
                'totalCalls': {
                    ...incomingCalls.totalCalls,
                    value: callsData?.totalCalls,
                    percentage: callsData?.pctChangeInTotalCalls,
                },
                'answeredCalls': {
                    ...incomingCalls.answeredCalls,
                    value: callsData?.answeredCalls,
                    percentage: callsData?.pctChangeInAnsweredCalls,
                },
                "answeredCallsInPct" : {
                    ...incomingCalls.answeredCallsInPct,
                    value: `${Number(callsData?.answeredCallsInPct).toFixed(1)} %`,
                    percentage: callsData?.pctChangeInAmbCallsInPct,

                },
                "ambCalls": {
                    ...incomingCalls.ambCalls,
                    value: callsData?.ambCalls,
                    percentage: callsData?.pctChangeInAmbCalls,
                },
                // "ambTrips": {
                //     ...incomingCalls.ambTrips,
                //     value: callsData?.totalAmbTrips,
                //     percentage: callsData?.conversionRateAmbTrips ?? '0'
                // }
            })

            setCallAggregateData({
                day: {
                    key: "key",
                    data: callsData?.aggCallsData?.["daily"]?.records,
                },
                weekly: {
                    key: "key",
                    data: callsData?.aggCallsData?.["weekly"]?.records,
                },
                monthly: {
                    key: "key",
                    data: callsData?.aggCallsData?.["monthly"]?.records,
                }
            })
        }
    }, [callsData]);


    const onChangesCurrentTab = (currentTab: string) => {
        setActiveTab(currentTab);
    }



    return (
        <Card
        className={`shadow-none mx-auto mt-2 border-[1px] overflow-hidden text-black`}
      >
        {/* <div className="bg-white"> */}
            <div className="text-xl font-semibold p-6">{'Incoming Calls'}</div>

            <div className="flex ml-6 mt-0">
            {Object.entries(incomingCalls).map((entry)=> {                
                return (
                    <div className={`flex flex-col px-6 chart-tab-wrapper py-5 ${activeTab === entry[0] && 'chart-active-tab relative top-px'} cursor-pointer`}
                        onClick={()=> onChangesCurrentTab(entry[0])}
                    >
                        <div className={`text-xs mb-2 ${activeTab === entry[0] ? 'text-[#175CD3]' : 'text-[#5B6169]'}`}>
                            {entry[1].text}
                        </div>
                        <div className="text-2xl font-semibold mb-2">
                            {entry[1].value}
                        </div>
                        <div className={`px-2 py-1 rounded-3xl ${entry[1].percentage > 0 ? 'bg-[#E9F3E8] text-[#008710]' : 'bg-[#FFECE9] text-[#E9231D]' } bg-[#E9F3E8] text-[#008710] text-xs w-fit flex items-center justify-center`}>
                            <div className="mr-1">
                                <Icon icon={entry[1].percentage > 0 ? "solar:course-up-bold" : "solar:course-down-bold"} color={ entry[1].percentage > 0 ? "#008710" : "#E9231D"}/>
                            </div>
                            {Math.abs(entry[1].percentage) + '%'}
                        </div>
                    </div>
                )
            })}
        </div>

            <Card className={`m-6 mt-0 shadow-none ${activeTab === 'totalCalls' && 'rounded-tl-none'}`}>
                <div className="flex justify-end p-3 px-6">
                   <TimeRangeSelector onSelectedValueChange={(value: any)=> setCurrentXAxisKey(value)}/>
                </div>
                <LineChartComponent dataXaxisKey={callAggregateData[currentXAxisKey]?.key} dataYAxisKey={activeTab} chartData={callAggregateData[currentXAxisKey]?.data}/>
            </Card>
        {/* </div>  */}
      </Card>
    )


}

export default IncomingCallsComponent;