import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { analyitcsInitialState } from "./analytics.constant";



const analyticsSlice = createSlice({
    name: 'analytics',
    initialState: analyitcsInitialState,
    reducers: {
        updateSelectedDateRange: (state, action: PayloadAction<any>) => {
            state.analyticsDateRange = action.payload
          },
    }
})


export default analyticsSlice.reducer;

export const {
    updateSelectedDateRange
} = analyticsSlice.actions